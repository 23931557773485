import { useMemo } from 'react';
import { useDatachecksStore } from 'stores/datachecks/datachecks';

type ShippingNoticeSummaryValues = {
  totalQuantity: number;
  transportUnitCount: number;
  deliveryDate?: number;
};

const calculateLineItemsQuantity = (lineItems: Array<any> | undefined): number => {
  return (
    lineItems?.reduce((acc, item) => {
      if (item.line_items?.length) {
        return acc + calculateLineItemsQuantity(item.line_items);
      }

      return acc + (item.shipped_quantity ?? 0);
    }, 0) || 0
  );
};

// Filter line items to only include those that don't have child items
// and sum up the shipped quantity
const calculateTotalQuantity = (transportUnits?: Array<any>): number =>
  transportUnits?.reduce((acc: number, transportUnit: any) => {
    const lineItemQuantity = calculateLineItemsQuantity(transportUnit.line_items);
    const subTransportUnitQuantity = calculateTotalQuantity(transportUnit.transport_units);
    return acc + lineItemQuantity + subTransportUnitQuantity;
  }, 0) ?? 0;

export const useShippingNoticeSummary = (): ShippingNoticeSummaryValues => {
  const { canonical } = useDatachecksStore();

  return useMemo(
    () => ({
      totalQuantity: calculateTotalQuantity(canonical?.transport_units),
      transportUnitCount: canonical?.transport_units?.length ?? 0,
      deliveryDate: canonical?.expected_delivery_date,
    }),
    [canonical?.transport_units, canonical?.expected_delivery_date],
  );
};
