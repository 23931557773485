import { Page } from 'components/Page/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'components/Form/Form';
import { Button } from 'components/Form/Button/Button';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { DisplayModes } from 'components/DataTable/Types';
import { DocumentType, RelationshipStatus, TransactionInternalType } from 'support/types/dtos';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { useHistoryStore } from 'stores/history/history';
import { useTranslation } from 'react-i18next';
import { DocumentSections } from 'components/WebEDI/DocumentSections/DocumentSections';
import { useRelationshipData } from '../hooks/useRelationshipData';
import { useDatachecksData } from '../hooks/useDatachecksData';
import { PriceList } from 'components/WebEDI/PriceList/PriceList';
import { getCanonical, terminateDataChecksWorker } from 'services/datachecks/datachecks';
import {
  useRelationshipDocumentCreation,
  useRelationshipDocumentEnrichment,
  useReceiverProcessSpecification,
} from 'services/repositories/relationships/relationships';
import { logError } from 'services/logging/logging';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { datachecksResolver } from 'support/helpers/resolvers/datachecksResolver';
import { cleanStore, setProcessSpecification, useDatachecksStore } from 'stores/datachecks/datachecks';
import { CreateInvoiceAndCreditNoteSummary } from 'components/WebEDI/CreateInvoiceAndCreditNoteSummary/CreateInvoiceAndCreditNoteSummary';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';
import { GenericError } from 'support/helpers/errors/errors';
import { DocumentTitle } from './components/DocumentTitle/DocumentTitle';
import { WebEDIMessageErrorBanner } from 'components/WebEDI/WebEDIMessageErrorBanner/WebEDIMessageErrorBanner';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { DocumentExtractor } from 'components/WebEDI/DocumentExtractor/DocumentExtractor';

const mode = DisplayModes.create;
type RouteParams = {
  messageId: string;
  relationshipId: string;
  messageType: DocumentType;
};

const useIntervalSinceMount = () => {
  const initialTime = useRef(Date.now());
  const getIntervalSinceMount = useCallback(() => Date.now() - initialTime.current, []);

  return getIntervalSinceMount;
};

export const CreateDocument = () => {
  const { track } = useAnalytics();
  const getIntervalSinceMount = useIntervalSinceMount();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { previousPath } = useHistoryStore();
  const [uploadModalOpen, setUploadModalOpen] = useState(!!searchParams.get('showUpload'));
  const shippingNoticeOcrEnabled = useFeatureFlagEnabled('ocr-shipping-notice');
  // const shippingNoticeOcrEnabled = true;

  const { enabled: priceCatalogsEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS);
  const { messageId: sourceMessageId, relationshipId, messageType: documentType } = useParams<RouteParams>();
  const { mutate: createDocument, isLoading: isCreating } = useRelationshipDocumentCreation();
  const { mutateAsync: enrichDocument } = useRelationshipDocumentEnrichment();

  const targetRelationship = useRelationshipData({ relationshipId, sourceMessageId, documentType });
  const flattenData = useDatachecksStore((state) => state.flattenData);
  const isLoading = useDatachecksStore((state) => state.isLoading);
  const uiConfig = useDatachecksStore((state) => state.uiConfig);
  const processSpecification = useDatachecksStore((state) => state.processSpecification);
  const resolver = useMemo(() => datachecksResolver(processSpecification), [processSpecification]);

  useEffect(() => {
    cleanStore();
    return () => {
      cleanStore();
      terminateDataChecksWorker();
    };
  }, []);

  const formMethods = useForm<Record<string, unknown>>({
    values: flattenData,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    watch,
    reset,
    trigger,
  } = formMethods;

  const { data: receiverProcessSpecification } = useReceiverProcessSpecification({
    variables: { relationshipId: targetRelationship?.id, sourceMessageId },
  });

  useEffect(() => {
    setProcessSpecification(receiverProcessSpecification);
  }, [receiverProcessSpecification]);

  useDatachecksData({
    targetRelationshipId: targetRelationship?.id,
    watch,
    reset,
    isSubmitted,
    trigger,
    documentType,
  });

  const submit = useCallback(async () => {
    if (!targetRelationship) {
      logError('No target relationship found');
      return;
    }

    if (!processSpecification) {
      logError('No process specification found');
      return;
    }

    const { canonical } = await getCanonical(processSpecification, formMethods.watch());
    const enrichedCanonical = await enrichDocument({
      relationshipId: targetRelationship.id,
      data: canonical,
    });
    createDocument(
      {
        relationshipId: targetRelationship.id,
        data: enrichedCanonical,
      },
      {
        onSuccess: ({ transactionId, transactionType }) => {
          track('new_document_send', {
            document_type: documentType,
            time_to_submit: getIntervalSinceMount(),
          });

          navigate(
            previousPath &&
              ![RelationshipStatus.LIVE, RelationshipStatus.ACTIVE].includes(
                RelationshipStatus[targetRelationship.status],
              )
              ? previousPath
              : routeToTransactionPage(transactionType, transactionId),
          );
        },
        onError: (e) => {
          logError(new GenericError('[WebEDIV2] Failed to create document: ', { errorMessage: e.message }));
          addNotification(
            {
              title: t('webedi:notifications.createDocumentBackendFailed.title', {
                documentType: t(`common:messageTypes.plural.${documentType}`),
              }),
              subtitle: t('webedi:notifications.createDocumentBackendFailed.subtitle', {
                documentType: t(`common:messageTypes.plural.${documentType}`),
              }),
            },
            NotificationType.error,
          );
        },
      },
    );
  }, [
    targetRelationship,
    processSpecification,
    formMethods,
    enrichDocument,
    createDocument,
    navigate,
    previousPath,
    t,
    documentType,
    track,
    getIntervalSinceMount,
  ]);

  const handleCancel = useCallback(() => {
    navigate(previousPath ? previousPath : routeToTransactionPage(TransactionInternalType.message, sourceMessageId!));
  }, [navigate, previousPath, sourceMessageId]);

  return (
    <Page isLoading={isLoading}>
      <FormProvider {...formMethods}>
        <Page.Head
          title={<DocumentTitle relationship={targetRelationship} />}
          rightContent={
            <div className="flex items-center gap-2">
              <Button
                variant="minimal"
                disabled={isSubmitting || isCreating}
                onClick={handleCancel}
                analyticsId="webedi:new_document_cancel"
              >
                {t('webedi:createDocument.cancel')}
              </Button>
              {!!documentType &&
                [DocumentType.invoice, DocumentType.creditNote].includes(documentType) &&
                mode === DisplayModes.create &&
                !priceCatalogsEnabled && <PriceList receiverPartnerId={targetRelationship?.receiver_partner?.id} />}
              {shippingNoticeOcrEnabled && (
                <Button
                  variant="secondary"
                  disabled={isSubmitting || isCreating}
                  onClick={() => setUploadModalOpen(true)}
                  analyticsId="webedi:new_document_upload"
                >
                  {t('webedi:createDocument.uploadDocument')}
                </Button>
              )}
              <Button
                type="submit"
                form="webedi-document"
                loading={isSubmitting || isCreating}
                RightIcon={PaperAirplaneIcon}
                analyticsId="webedi:new_document_send"
              >
                {t('webedi:createDocument.submit')}
              </Button>
            </div>
          }
        />

        <Page.Section
          topBanner={Object.keys(errors).length ? <WebEDIMessageErrorBanner mode={mode} blockingError={null} /> : null}
          bottomBanner={
            documentType &&
            [DocumentType.invoice, DocumentType.creditNote].includes(documentType) &&
            mode === DisplayModes.create ? (
              <CreateInvoiceAndCreditNoteSummary
                documentType={documentType as DocumentType.creditNote | DocumentType.invoice}
              />
            ) : null
          }
        >
          <Form
            noValidate={true}
            onSubmit={handleSubmit(submit)}
            id="webedi-document"
            data-testid="webedi-document"
            autoComplete="off"
          >
            {uiConfig && documentType ? (
              <DocumentSections mode={mode} uiConfig={uiConfig} documentType={documentType} />
            ) : null}
            <DocumentExtractor open={uploadModalOpen && !!shippingNoticeOcrEnabled} setOpen={setUploadModalOpen} />
          </Form>
        </Page.Section>
      </FormProvider>
    </Page>
  );
};
