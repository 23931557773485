import { Button } from 'components/Form/Button/Button';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  downloadAsCSV,
  downloadAsPDF,
  useConfirmMessageReceival,
} from 'services/repositories/transactions/transactions';
import { addNotification } from 'stores/notifications/notifications';
import { routeToCreatePage } from 'support/helpers/navigation/navigation';
import { Connectors, DocumentType, MessageDTO, MessageV2DTO, ReceiverEnvelopeState, ValueOf } from 'support/types';
import { AlreadyExistingMessageModal } from './AlreadyExistingMessageModal';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { isMessageV2DTO } from 'support/helpers/transactions/transactions';
import { AnalyticsProps } from 'support/helpers/analytics/analytics';
import { useFeatureFlagEnabled } from 'posthog-js/react';

type ActionConfig = {
  label: string;
  test?: (message: MessageDTO | MessageV2DTO) => void;
  alternativeAction?: (message: MessageDTO | MessageV2DTO) => void;
  documentType?: DocumentType;
  testId?: string;
  secondaryOptionOnly?: boolean;
} & AnalyticsProps;

type DocumentTypeActionConfig = {
  documentType: DocumentType;
  actions: Array<ActionConfig>;
};

type GetActionsReturn = {
  primaryAction: DocumentAction | null;
  secondaryActions: Array<DocumentAction>;
};

type DocumentAction = {
  label: string;
  alternativeAction?: (message: MessageDTO | MessageV2DTO) => void;
  documentType?: DocumentType;
  testId?: string;
  documentTypeExists: boolean;
} & AnalyticsProps;

const mapOptionsToDropdown = (options: Array<DocumentAction>, t: TFunction) => {
  return options.map((option: DocumentAction) => ({
    label: t(option.label),
    value: option.label,
    testId: option.testId,
    analyticsId: option.analyticsId,
    analyticsProperties: option.analyticsProperties,
  }));
};

type ActionButtonsProps = {
  fromDocument: MessageDTO | MessageV2DTO;
  relatedTransactions: Array<MessageDTO | MessageV2DTO>;
  enabledDocumentTypes: Array<DocumentType>;
  fromDocumentType: ValueOf<DocumentType>;
};

export function ActionButtons({
  fromDocument,
  relatedTransactions,
  enabledDocumentTypes,
  fromDocumentType,
}: ActionButtonsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedExistingDocumentType, setSelectedExistingDocumentType] = useState<DocumentType>();
  const confirmMessageReceivalMutation = useConfirmMessageReceival();
  const shippingNoticeOcrEnabled = useFeatureFlagEnabled('ocr-shipping-notice');
  // const shippingNoticeOcrEnabled = true;

  // t('transactions:show.actionButtons.createOrderResponse')
  const createOrderResponseAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createOrderResponse',
    documentType: DocumentType.orderResponse,
    analyticsId: 'transactions:create_order_response',
  };

  // t('transactions:show.actionButtons.createShippingNotice')
  const createShippingNoticeAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createShippingNotice',
    documentType: DocumentType.shippingNotice,
    analyticsId: 'transactions:create_shipping_notice',
  };

  // t('transactions:show.actionButtons.confirmAsOrdered')
  const confirmAsOrderedAction: ActionConfig = {
    label: 'transactions:show.actionButtons.confirmAsOrdered',
    analyticsId: 'transactions:confirm_as_ordered',
    test: (message: MessageDTO | MessageV2DTO) => {
      if (isMessageV2DTO(message)) {
        return (
          message.receiver_envelope?.state === ReceiverEnvelopeState.pendingPickup &&
          message.receiver_integration?.connector === Connectors.WEB_EDI
        );
      }
      return (
        message.receiverEnvelope?.state === ReceiverEnvelopeState.pendingPickup &&
        message.receiverIntegration?.connector === Connectors.WEB_EDI
      );
    },

    alternativeAction: (message: MessageDTO | MessageV2DTO) => {
      if (isMessageV2DTO(message)) {
        return (
          !!message.receiver_envelope?.id &&
          confirmMessageReceivalMutation.mutate(
            {
              messageId: message.id,
              receiverEnvelopeId: message.receiver_envelope.id,
            },
            {
              onSuccess: () => {
                addNotification(t('webedi:notifications.orderConfirmed'));
              },
            },
          )
        );
      }

      return (
        !!message.receiverEnvelope?.id &&
        confirmMessageReceivalMutation.mutate(
          {
            messageId: message.id,
            receiverEnvelopeId: message.receiverEnvelope.id,
          },
          {
            onSuccess: () => {
              addNotification(t('webedi:notifications.orderConfirmed'));
            },
          },
        )
      );
    },
  };

  // t('transactions:show.actionButtons.createInvoice')
  const createInvoiceAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createInvoice',
    documentType: DocumentType.invoice,
    analyticsId: 'transactions:create_invoice',
  };

  // t('transactions:show.actionButtons.createCreditNote')
  const createCreditNoteAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createCreditNote',
    documentType: DocumentType.creditNote,
    analyticsId: 'transactions:create_credit_note',
  };

  // t('transactions:show.actionButtons.downloadAsCSV')
  const downloadAsCSVAction: ActionConfig = {
    label: 'transactions:show.actionButtons.downloadAsCSV',
    alternativeAction: (message: MessageDTO | MessageV2DTO) => downloadAsCSV(message),
    secondaryOptionOnly: true,
    analyticsId: 'transactions:download_as_csv',
  };

  // t('transactions:show.actionButtons.downloadAsPDF')
  const downloadAsPDFAction: ActionConfig = {
    label: 'transactions:show.actionButtons.downloadAsPDF',
    alternativeAction: (message: MessageDTO | MessageV2DTO) => downloadAsPDF(message),
    secondaryOptionOnly: true,
    analyticsId: 'transactions:download_as_pdf',
  };

  const orderActions: DocumentTypeActionConfig = {
    documentType: DocumentType.order,
    actions: [
      confirmAsOrderedAction,
      createOrderResponseAction,
      createShippingNoticeAction,
      createInvoiceAction,
      downloadAsPDFAction,
      downloadAsCSVAction,
    ],
  };

  const orderResponseActions: DocumentTypeActionConfig = {
    documentType: DocumentType.orderResponse,
    actions: [createShippingNoticeAction, createInvoiceAction],
  };

  const shippingNoticeActions: DocumentTypeActionConfig = {
    documentType: DocumentType.shippingNotice,
    actions: [createInvoiceAction],
  };

  const invoiceActions: DocumentTypeActionConfig = {
    documentType: DocumentType.invoice,
    actions: [createCreditNoteAction],
  };

  const creditNoteActions: DocumentTypeActionConfig = {
    documentType: DocumentType.creditNote,
    actions: [],
  };

  const documentActions: Array<DocumentTypeActionConfig> = [
    orderActions,
    orderResponseActions,
    shippingNoticeActions,
    invoiceActions,
    creditNoteActions,
  ];

  const actions: Array<ActionConfig> =
    documentActions.find((action: DocumentTypeActionConfig) => action.documentType === fromDocumentType)?.actions ?? [];

  const availableActions = actions
    // Only actions for enabled document types or without a document type
    .filter(
      (action: ActionConfig) =>
        typeof action.documentType === 'undefined' || enabledDocumentTypes.includes(action.documentType),
    )
    // Only actions that pass their test or without a test
    .filter((action: ActionConfig) => !action.test || action.test(fromDocument))
    // Add if document already exists
    .map((action: ActionConfig) => ({
      ...action,
      documentTypeExists: relatedTransactions.some(
        (transaction: MessageDTO | MessageV2DTO) => transaction.type === action.documentType,
      ),
    }));

  const { primaryAction, secondaryActions } = availableActions.reduce(
    (acc: GetActionsReturn, action) => {
      if (!acc.primaryAction && !action.secondaryOptionOnly && !action.documentTypeExists) {
        return {
          ...acc,
          primaryAction: action,
        };
      }

      return {
        ...acc,
        secondaryActions: [...acc.secondaryActions, action],
      };
    },
    { primaryAction: null, secondaryActions: [] },
  );

  const navigateToForm = (documentType: DocumentType, showUploadModal?: boolean) => {
    navigate(routeToCreatePage(fromDocument.id, documentType, showUploadModal));
  };

  const executeOption = (actionConfig?: DocumentAction) => {
    if (!actionConfig) {
      return;
    }

    const { alternativeAction, documentType, documentTypeExists } = actionConfig;

    if (alternativeAction) {
      alternativeAction(fromDocument);
      return;
    }

    if (!documentType) {
      return;
    }

    if (documentTypeExists) {
      setSelectedExistingDocumentType(documentType);
      return;
    }

    navigateToForm(documentType);
  };

  const hideModal = () => {
    setSelectedExistingDocumentType(undefined);
  };

  const confirmCreateAgain = () => {
    hideModal();
    navigateToForm(selectedExistingDocumentType!);
  };

  const primaryButton = primaryAction ? (
    shippingNoticeOcrEnabled && primaryAction.documentType === DocumentType.shippingNotice ? (
      <MenuButton
        className="ml-2"
        variant="primary"
        text={t(primaryAction.label)}
        size="base"
        analyticsId="transactions:primary_action_ocr"
      >
        <MenuButtonOption
          key="manually"
          onClick={() => {
            navigateToForm(primaryAction.documentType!);
          }}
          analyticsId={primaryAction.analyticsId}
          analyticsProperties={primaryAction.analyticsProperties}
        >
          {t('transactions:show.actionButtons.createManually')}
        </MenuButtonOption>
        <MenuButtonOption
          key="upload"
          onClick={() => {
            navigateToForm(primaryAction.documentType!, true);
          }}
          analyticsId={primaryAction.analyticsId}
          analyticsProperties={primaryAction.analyticsProperties}
        >
          {t('transactions:show.actionButtons.uploadDocument')}
        </MenuButtonOption>
      </MenuButton>
    ) : (
      <Button
        className="ml-2"
        loading={confirmMessageReceivalMutation.isLoading}
        onClick={() => executeOption(primaryAction)}
        testId="ActionButtonPrimaryAction"
        analyticsId="transactions:primary_action"
      >
        {t(primaryAction.label)}
      </Button>
    )
  ) : null;

  return (
    <div className="flex flex-row items-center">
      {!!secondaryActions.length && (
        <MenuButton
          variant="secondary"
          text={t('transactions:show.actionButtons.moreOptions')}
          testId="ActionButtonOptions"
          size="base"
          analyticsId="transactions:secondary_actions"
        >
          {mapOptionsToDropdown(secondaryActions, t).map((action, i) => {
            return (
              <MenuButtonOption
                data-testid={action.testId}
                key={action.value}
                onClick={() => {
                  executeOption(secondaryActions[i]);
                }}
                analyticsId={action.analyticsId}
                analyticsProperties={action.analyticsProperties}
              >
                {action.label}
              </MenuButtonOption>
            );
          })}
        </MenuButton>
      )}
      {primaryButton}

      <AlreadyExistingMessageModal
        isOpen={!!selectedExistingDocumentType}
        onAction={confirmCreateAgain}
        onClose={hideModal}
      />
    </div>
  );
}
