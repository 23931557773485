import { useTranslation } from 'react-i18next';

import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';

import { ResetPasswordForm } from '../ResetPasswordForm/ResetPasswordForm';

export function ResetPassword() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ApplicationLogo className="w-auto h-16 mx-auto bg-black" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <ResetPasswordForm introText={t('auth:resetPasswordText')} />
      </div>
    </div>
  );
}
