import { ui } from '@procuros/datachecks';
import { useFlattenData } from 'hooks/useFlattenData';
import { JSONSchema7 } from 'json-schema';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { UseFormReset, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { applyRowMasksToData, getCanonical } from 'services/datachecks/datachecks';
import { logError } from 'services/logging/logging';
import {
  useDocTypeDocumentEnrichment,
  useRelationshipDocumentEnrichment,
} from 'services/repositories/relationships/relationships';
import { creditNoteEnrichmentSchema } from 'services/webedi/enrichmentSchemas/creditNote';
import { invoiceEnrichmentSchema } from 'services/webedi/enrichmentSchemas/invoice';
import { validateAgainstEnrichmentSchema } from 'services/webedi/webedi';
import {
  setFlattenData,
  setInitialData,
  setIsLoading,
  setUiConfig,
  useDatachecksStore,
} from 'stores/datachecks/datachecks';
import useDebouncePromise, { CANCELLED_ERROR } from 'support/helpers/hooks/useDebouncePromise';
import { DocumentType, ProcessSpecificationDTO } from 'support/types';
import { useDebounceValue } from 'usehooks-ts';

const DEBOUNCE_TIME = 2000;
export const useDatachecksData = ({
  targetRelationshipId,
  documentType,
  isSubmitted,
  reset,
  trigger,
  watch,
}: {
  targetRelationshipId: string | undefined;
  documentType: DocumentType | undefined;
  reset: UseFormReset<any>;
  isSubmitted: boolean;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
}) => {
  const processSpecification = useDatachecksStore((state) => state.processSpecification);
  const [formData, setFormData] = useDebounceValue(undefined, DEBOUNCE_TIME);
  useEffect(() => {
    const { unsubscribe } = watch((value: any) => {
      setFormData(value);
    });
    return () => unsubscribe();
  }, [setFormData, watch]);

  const { mutate: enrichDocumentBasedOnRelationship } = useRelationshipDocumentEnrichment();
  const { mutate: enrichDocumentBasedOnDocType } = useDocTypeDocumentEnrichment();
  const { data, uiConfig, isLoading } = useFlattenData(processSpecification);
  const debouncedProcessFormData = useDebouncePromise(processFormData, DEBOUNCE_TIME);
  useEffect(() => {
    let isCancelled = false;
    const executeProcessFormData = async () => {
      try {
        const { flattenData, uiConfig } = await debouncedProcessFormData({
          formData,
          receiverProcessSpecification: processSpecification,
          targetRelationshipId,
          documentType,
          enrichDocument: targetRelationshipId ? enrichDocumentBasedOnRelationship : enrichDocumentBasedOnDocType,
        });

        if ((window as any).procuros_debug) {
          console.log({ flattenData, uiConfig });
        }

        if (!isCancelled) {
          if ((window as any).procuros_debug) {
            console.warn('merging flattened data and uiConfig');
          }

          let filteredFlattenData = flattenData;
          if (uiConfig && flattenData) {
            filteredFlattenData = applyRowMasksToData(flattenData, uiConfig);
          }
          // Just updating the flattenData on the store
          // does not work as there is a race condition
          // that causes trigger() to execute the form validation
          // using stale data.
          reset(filteredFlattenData, {
            keepIsSubmitted: true,
          });
          if (isSubmitted) {
            trigger();
          }
          setUiConfig(uiConfig);
        }
      } catch (error) {
        if ((error as Error).message !== CANCELLED_ERROR) logError(error);
      }
    };
    executeProcessFormData();
    return () => {
      debouncedProcessFormData.cancel();
      isCancelled = true;
    };
  }, [
    documentType,
    enrichDocumentBasedOnRelationship,
    enrichDocumentBasedOnDocType,
    processSpecification,
    targetRelationshipId,
    formData,
    reset,
    trigger,
    debouncedProcessFormData,
  ]);

  useEffect(() => {
    if (uiConfig) {
      setUiConfig(uiConfig);
    }

    if (data) {
      let filteredData = data;
      if (uiConfig) {
        filteredData = applyRowMasksToData(data, uiConfig);
      }

      setInitialData(filteredData);
      setFlattenData(filteredData);
    }
  }, [data, uiConfig]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);
};

type ProcessFormDataArgs = {
  formData: any;
  receiverProcessSpecification: ProcessSpecificationDTO | undefined;
  targetRelationshipId: string | undefined;
  documentType: DocumentType | undefined;
  enrichDocument: any;
};

type ProcessFormReturn = Promise<{ flattenData: any; uiConfig: any }>;

let previousEnrichedCanonical: any = null;
const processFormData = ({
  formData,
  receiverProcessSpecification,
  targetRelationshipId,
  documentType,
  enrichDocument,
}: ProcessFormDataArgs): ProcessFormReturn => {
  return new Promise((resolve, reject) => {
    try {
      const hasData = formData && Object.keys(formData).length > 0;
      if (receiverProcessSpecification && hasData) {
        getCanonical(receiverProcessSpecification, formData)
          .then((wrappedCanonical) => {
            const canonical = wrappedCanonical.canonical;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).procuros_debug) {
              console.log('Result canonical', canonical);
            }

            let enrichmentSchema: JSONSchema7 | undefined = undefined;
            switch (documentType) {
              case DocumentType.invoice:
                enrichmentSchema = invoiceEnrichmentSchema;
                break;
              case DocumentType.creditNote:
                enrichmentSchema = creditNoteEnrichmentSchema;
                break;
            }
            const shouldEnrich = validateAgainstEnrichmentSchema(enrichmentSchema, canonical);
            if (shouldEnrich) {
              // @vasco: need better code for this. enrichment can receive either relationshipId or docType
              enrichDocument(
                {
                  relationshipId: targetRelationshipId,
                  data: canonical,
                  docType: documentType,
                },
                {
                  onSuccess: (enrichedDocument: any) => {
                    if (!isEqual(enrichedDocument, previousEnrichedCanonical)) {
                      previousEnrichedCanonical = enrichedDocument;
                      ui.view({ fields: receiverProcessSpecification?.fields as any }, enrichedDocument)
                        .then((out) => {
                          resolve({ flattenData: out.values, uiConfig: out.view });
                        })
                        .catch(logError);
                    }
                  },
                },
              );
            }
          })
          .catch((error) => {
            logError(error);
            reject(error);
          });
      }
    } catch (error) {
      logError(error);
      reject(error);
    }
  });
};
