import { FileInput } from 'components/Form/FileInput/FileInput';
import { InputLabel } from 'components/Form/Input/InputLabel/InputLabel';
import { LoadingLogo } from 'components/Loading/LoadingLogo';
import { useTranslation } from 'react-i18next';

export const FileUploader = ({
  onClear,
  onError,
  onLoad,
  label,
  accept,
  loader,
  isUploading,
}: Parameters<typeof FileInput>[0] & { isUploading: boolean }) => {
  const { t } = useTranslation();
  return isUploading ? (
    <div>
      <InputLabel label={label} />
      <div className="relative h-[135px] w-full rounded-md bg-gray-100">
        <span className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-3 text-gray-300">
          <span className="size-12 origin-center">
            <LoadingLogo />
          </span>
          {t('common:components.fileUploader.uploading')}
        </span>
      </div>
    </div>
  ) : (
    <FileInput
      label={label}
      onLoad={(data, filename) => {
        onLoad(data, filename);
      }}
      onError={onError}
      onClear={onClear}
      loader={loader}
      accept={accept}
    />
  );
};
