import { logError } from 'services/logging/logging';

const DEFAULT_CURRENCY = 'EUR';

export const formatCurrency = (
  value: number,
  currency: string | undefined,
  options: Omit<Intl.NumberFormatOptions, 'currency'> = { maximumFractionDigits: 2 },
) => {
  try {
    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: currency || DEFAULT_CURRENCY,
      ...options,
    }).format(value || 0);
  } catch (e) {
    logError(e);
    return `${value}`;
  }
};

export const getCurrencySymbol = (currency: string | undefined | null) => {
  return (0)
    .toLocaleString(navigator.language, {
      style: 'currency',
      currency: currency || DEFAULT_CURRENCY,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};
