import { JSONSchema7 } from 'json-schema';

export const invoiceEnrichmentSchema: JSONSchema7 = {
  type: 'object',
  required: ['line_items'],
  additionalProperties: true,
  properties: {
    invoice_identifier: {
      type: 'string',
    },
    invoice_date: {
      type: 'number',
    },
    invoice_due_date: {
      type: 'number',
    },
    shipping_notice_identifier: {
      type: 'string',
    },
    shipping_notice_date: {
      type: 'number',
    },
    order_identifier: {
      type: 'string',
    },
    order_date: {
      type: 'number',
    },
    currency: {
      type: 'string',
      default: 'EUR',
    },
    requested_delivery_date: {
      type: 'number',
    },
    expected_delivery_date: {
      type: 'number',
    },
    contract_identifier: {
      type: 'string',
    },
    promotion_identifier: {
      type: 'string',
    },
    comments: {
      type: 'string',
    },
    payment_terms: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: true,
        properties: {
          pay_in_number_of_days: {
            type: 'number',
          },
          discount_percentage: {
            type: 'number',
          },
        },
      },
    },
    parties: {
      type: 'object',
      additionalProperties: true,
      properties: {
        buyer: {
          type: 'object',
          additionalProperties: true,
          properties: {
            name: {
              type: 'string',
            },
            identifiers: {
              type: 'object',
              additionalProperties: true,
              properties: {
                gln: {
                  type: 'string',
                },
                vat: {
                  type: 'string',
                },
                duns: {
                  type: 'string',
                },
                sender_internal: {
                  type: 'string',
                },
                receiver_internal: {
                  type: 'string',
                },
                organic_control_point_number: {
                  type: 'string',
                },
                federal_tax: {
                  type: 'string',
                },
              },
            },
            contacts: {
              type: 'object',
              additionalProperties: true,
              properties: {
                main: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    first_name: {
                      type: 'string',
                    },
                    last_name: {
                      type: 'string',
                    },
                    email: {
                      type: 'string',
                    },
                    fax: {
                      type: 'string',
                    },
                    phone: {
                      type: 'string',
                    },
                    custom_fields: {
                      type: 'object',
                      additionalProperties: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
            postal_address: {
              type: 'object',
              additionalProperties: true,
              properties: {
                street: {
                  type: 'string',
                },
                city: {
                  type: 'string',
                },
                state: {
                  type: 'string',
                },
                country_code: {
                  type: 'string',
                },
                postal_code: {
                  type: 'string',
                },
                address_extra: {
                  type: 'string',
                },
              },
            },
            custom_fields: {
              type: 'object',
              additionalProperties: {
                type: 'string',
              },
            },
          },
        },
        supplier: {
          type: 'object',
          additionalProperties: true,
          properties: {
            name: {
              type: 'string',
            },
            identifiers: {
              type: 'object',
              additionalProperties: true,
              properties: {
                gln: {
                  type: 'string',
                },
                vat: {
                  type: 'string',
                },
                duns: {
                  type: 'string',
                },
                sender_internal: {
                  type: 'string',
                },
                receiver_internal: {
                  type: 'string',
                },
                organic_control_point_number: {
                  type: 'string',
                },
                federal_tax: {
                  type: 'string',
                },
              },
            },
            contacts: {
              type: 'object',
              additionalProperties: true,
              properties: {
                main: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    first_name: {
                      type: 'string',
                    },
                    last_name: {
                      type: 'string',
                    },
                    email: {
                      type: 'string',
                    },
                    fax: {
                      type: 'string',
                    },
                    phone: {
                      type: 'string',
                    },
                    custom_fields: {
                      type: 'object',
                      additionalProperties: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
            postal_address: {
              type: 'object',
              additionalProperties: true,
              properties: {
                street: {
                  type: 'string',
                },
                city: {
                  type: 'string',
                },
                state: {
                  type: 'string',
                },
                country_code: {
                  type: 'string',
                },
                postal_code: {
                  type: 'string',
                },
                address_extra: {
                  type: 'string',
                },
              },
            },
            custom_fields: {
              type: 'object',
              additionalProperties: {
                type: 'string',
              },
            },
          },
        },
        ship_to: {
          type: 'object',
          additionalProperties: true,
          properties: {
            name: {
              type: 'string',
            },
            identifiers: {
              type: 'object',
              additionalProperties: true,
              properties: {
                gln: {
                  type: 'string',
                },
                vat: {
                  type: 'string',
                },
                duns: {
                  type: 'string',
                },
                sender_internal: {
                  type: 'string',
                },
                receiver_internal: {
                  type: 'string',
                },
                organic_control_point_number: {
                  type: 'string',
                },
                federal_tax: {
                  type: 'string',
                },
              },
            },
            contacts: {
              type: 'object',
              additionalProperties: true,
              properties: {
                main: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    first_name: {
                      type: 'string',
                    },
                    last_name: {
                      type: 'string',
                    },
                    email: {
                      type: 'string',
                    },
                    fax: {
                      type: 'string',
                    },
                    phone: {
                      type: 'string',
                    },
                    custom_fields: {
                      type: 'object',
                      additionalProperties: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
            postal_address: {
              type: 'object',
              additionalProperties: true,
              properties: {
                street: {
                  type: 'string',
                },
                city: {
                  type: 'string',
                },
                state: {
                  type: 'string',
                },
                country_code: {
                  type: 'string',
                },
                postal_code: {
                  type: 'string',
                },
                address_extra: {
                  type: 'string',
                },
              },
            },
            custom_fields: {
              type: 'object',
              additionalProperties: {
                type: 'string',
              },
            },
          },
        },
        bill_to: {
          type: 'object',
          additionalProperties: true,
          properties: {
            name: {
              type: 'string',
            },
            identifiers: {
              type: 'object',
              additionalProperties: true,
              properties: {
                gln: {
                  type: 'string',
                },
                vat: {
                  type: 'string',
                },
                duns: {
                  type: 'string',
                },
                sender_internal: {
                  type: 'string',
                },
                receiver_internal: {
                  type: 'string',
                },
                organic_control_point_number: {
                  type: 'string',
                },
                federal_tax: {
                  type: 'string',
                },
              },
            },
            contacts: {
              type: 'object',
              additionalProperties: true,
              properties: {
                main: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    first_name: {
                      type: 'string',
                    },
                    last_name: {
                      type: 'string',
                    },
                    email: {
                      type: 'string',
                    },
                    fax: {
                      type: 'string',
                    },
                    phone: {
                      type: 'string',
                    },
                    custom_fields: {
                      type: 'object',
                      additionalProperties: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
            postal_address: {
              type: 'object',
              additionalProperties: true,
              properties: {
                street: {
                  type: 'string',
                },
                city: {
                  type: 'string',
                },
                state: {
                  type: 'string',
                },
                country_code: {
                  type: 'string',
                },
                postal_code: {
                  type: 'string',
                },
                address_extra: {
                  type: 'string',
                },
              },
            },
            custom_fields: {
              type: 'object',
              additionalProperties: {
                type: 'string',
              },
            },
          },
        },
        bill_from: {
          type: 'object',
          additionalProperties: true,
          properties: {
            name: {
              type: 'string',
            },
            identifiers: {
              type: 'object',
              additionalProperties: true,
              properties: {
                gln: {
                  type: 'string',
                },
                vat: {
                  type: 'string',
                },
                duns: {
                  type: 'string',
                },
                sender_internal: {
                  type: 'string',
                },
                receiver_internal: {
                  type: 'string',
                },
                organic_control_point_number: {
                  type: 'string',
                },
                federal_tax: {
                  type: 'string',
                },
              },
            },
            contacts: {
              type: 'object',
              additionalProperties: true,
              properties: {
                main: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    first_name: {
                      type: 'string',
                    },
                    last_name: {
                      type: 'string',
                    },
                    email: {
                      type: 'string',
                    },
                    fax: {
                      type: 'string',
                    },
                    phone: {
                      type: 'string',
                    },
                    custom_fields: {
                      type: 'object',
                      additionalProperties: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
            postal_address: {
              type: 'object',
              additionalProperties: true,
              properties: {
                street: {
                  type: 'string',
                },
                city: {
                  type: 'string',
                },
                state: {
                  type: 'string',
                },
                country_code: {
                  type: 'string',
                },
                postal_code: {
                  type: 'string',
                },
                address_extra: {
                  type: 'string',
                },
              },
            },
            custom_fields: {
              type: 'object',
              additionalProperties: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    line_items: {
      type: 'array',
      items: {
        type: 'object',
        required: ['item', 'invoiced_quantity'],
        additionalProperties: true,
        properties: {
          invoice_line_identifier: {
            type: 'string',
          },
          shipping_notice_line_identifier: {
            type: 'string',
          },
          order_line_identifier: {
            type: 'string',
          },
          shipping_notice_identifier: {
            type: 'string',
          },
          shipping_notice_date: {
            type: 'number',
          },
          order_identifier: {
            type: 'string',
          },
          order_date: {
            type: 'number',
          },
          item: {
            type: 'object',
            required: ['type', 'unit_price'],
            additionalProperties: true,
            properties: {
              type: {
                oneOf: [
                  {
                    const: 'PRODUCT',
                  },
                  {
                    const: 'SERVICE',
                  },
                  {
                    const: 'RETURNABLE_CONTAINER',
                  },
                  {
                    const: 'NON_RETURNABLE_CONTAINER',
                  },
                  {
                    const: 'PACKAGING',
                  },
                  {
                    const: 'OTHER',
                  },
                ],
              },
              identifiers: {
                type: 'object',
                additionalProperties: true,
                properties: {
                  gtin: {
                    type: 'string',
                  },
                  sender_internal: {
                    type: 'string',
                  },
                  receiver_internal: {
                    type: 'string',
                  },
                },
              },
              name: {
                type: 'string',
              },
              unit_of_measure: {
                type: 'string',
                default: 'EA',
              },
              unit_price: {
                type: 'number',
                minimum: 0,
              },
              tax_percentage: {
                type: 'number',
                minimum: 0,
              },
              tax_type: {
                oneOf: [
                  {
                    const: 'VAT',
                  },
                  {
                    const: 'EXEMPT',
                  },
                  {
                    const: 'ZERO_RATED',
                  },
                ],
              },
              net_weight: {
                type: 'number',
              },
              gross_weight: {
                type: 'number',
              },
              origin_country_code: {
                type: 'string',
              },
              color_code: {
                type: 'string',
              },
              color_name: {
                type: 'string',
              },
              size: {
                type: 'string',
              },
              dimension: {
                type: 'string',
              },
              hs_code: {
                type: 'string',
              },
              custom_fields: {
                type: 'object',
                additionalProperties: {
                  type: 'string',
                },
              },
            },
          },
          ordered_quantity: {
            type: 'number',
            minimum: 0,
          },
          invoiced_quantity: {
            type: 'number',
            minimum: 0,
          },
          free_quantity: {
            type: 'number',
            minimum: 0,
          },
          line_gross_amount: {
            type: 'number',
          },
          line_allowance_amount: {
            type: 'number',
          },
          line_charge_amount: {
            type: 'number',
          },
          line_net_amount: {
            type: 'number',
          },
          line_tax_amount: {
            type: 'number',
          },
          line_due_amount: {
            type: 'number',
          },
          contract_identifier: {
            type: 'string',
          },
          promotion_identifier: {
            type: 'string',
          },
          requested_delivery_date: {
            type: 'number',
          },
          expected_delivery_date: {
            type: 'number',
          },
          comments: {
            type: 'string',
          },
          batches: {
            type: 'array',
            items: {
              type: 'object',
              additionalProperties: true,
              properties: {
                identifier: {
                  type: 'string',
                },
                quantity: {
                  type: 'number',
                  minimum: 0,
                },
                expiration_date: {
                  type: 'number',
                },
                gross_weight: {
                  type: 'number',
                },
                net_weight: {
                  type: 'number',
                },
                custom_fields: {
                  type: 'object',
                  additionalProperties: {
                    type: 'string',
                  },
                },
              },
            },
          },
          modification_groups: {
            type: 'array',
            items: {
              type: 'object',
              additionalProperties: true,
              properties: {
                basis: {
                  type: 'number',
                },
                comments: {
                  type: 'string',
                },
                charges: {
                  type: 'array',
                  items: {
                    type: 'object',
                    required: ['type'],
                    additionalProperties: true,
                    properties: {
                      type: {
                        default: 'RELATIVE',
                        oneOf: [
                          {
                            const: 'RELATIVE',
                          },
                          {
                            const: 'ABSOLUTE',
                          },
                        ],
                      },
                      description: {
                        type: 'string',
                      },
                      reason_code: {
                        default: 'SHIPPING',
                        oneOf: [
                          {
                            const: 'SHIPPING',
                          },
                          {
                            const: 'PACKAGING',
                          },
                          {
                            const: 'HANDLING',
                          },
                          {
                            const: 'DISCOUNT',
                          },
                        ],
                      },
                      percentage: {
                        type: 'number',
                        minimum: 0,
                      },
                      amount: {
                        type: 'number',
                        minimum: 0,
                      },
                      tax: {
                        type: 'object',
                        additionalProperties: true,
                        properties: {
                          total_tax_amount: {
                            type: 'number',
                          },
                          items: {
                            type: 'array',
                            items: {
                              type: 'object',
                              additionalProperties: true,
                              properties: {
                                taxable_amount: {
                                  type: 'number',
                                },
                                tax_amount: {
                                  type: 'number',
                                  minimum: 0,
                                },
                                tax_percentage: {
                                  type: 'number',
                                  minimum: 0,
                                },
                                tax_type: {
                                  oneOf: [
                                    {
                                      const: 'VAT',
                                    },
                                    {
                                      const: 'EXEMPT',
                                    },
                                    {
                                      const: 'ZERO_RATED',
                                    },
                                  ],
                                },
                              },
                            },
                          },
                        },
                      },
                      custom_fields: {
                        type: 'object',
                        additionalProperties: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
                allowances: {
                  type: 'array',
                  items: {
                    type: 'object',
                    required: ['type'],
                    additionalProperties: true,
                    properties: {
                      type: {
                        default: 'RELATIVE',
                        oneOf: [
                          {
                            const: 'RELATIVE',
                          },
                          {
                            const: 'ABSOLUTE',
                          },
                        ],
                      },
                      description: {
                        type: 'string',
                      },
                      reason_code: {
                        oneOf: [
                          {
                            const: 'SHIPPING',
                          },
                          {
                            const: 'PACKAGING',
                          },
                          {
                            const: 'HANDLING',
                          },
                          {
                            const: 'DISCOUNT',
                          },
                        ],
                        default: 'SHIPPING',
                      },
                      percentage: {
                        type: 'number',
                        minimum: 0,
                      },
                      amount: {
                        type: 'number',
                        minimum: 0,
                      },
                      tax: {
                        type: 'object',
                        additionalProperties: true,
                        properties: {
                          total_tax_amount: {
                            type: 'number',
                          },
                          items: {
                            type: 'array',
                            items: {
                              type: 'object',
                              additionalProperties: true,
                              properties: {
                                taxable_amount: {
                                  type: 'number',
                                },
                                tax_amount: {
                                  type: 'number',
                                  minimum: 0,
                                },
                                tax_percentage: {
                                  type: 'number',
                                  minimum: 0,
                                },
                                tax_type: {
                                  oneOf: [
                                    {
                                      const: 'VAT',
                                    },
                                    {
                                      const: 'EXEMPT',
                                    },
                                    {
                                      const: 'ZERO_RATED',
                                    },
                                  ],
                                },
                              },
                            },
                          },
                        },
                      },
                      custom_fields: {
                        type: 'object',
                        additionalProperties: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
                custom_fields: {
                  type: 'object',
                  additionalProperties: {
                    type: 'string',
                  },
                },
              },
            },
          },
          line_items: {
            type: 'array',
            items: {
              type: 'object',
              required: ['item', 'invoiced_quantity'],
              additionalProperties: true,
              properties: {
                invoice_line_identifier: {
                  type: 'string',
                },
                shipping_notice_line_identifier: {
                  type: 'string',
                },
                order_line_identifier: {
                  type: 'string',
                },
                shipping_notice_identifier: {
                  type: 'string',
                },
                shipping_notice_date: {
                  type: 'number',
                },
                order_identifier: {
                  type: 'string',
                },
                order_date: {
                  type: 'number',
                },
                item: {
                  type: 'object',
                  required: ['type', 'unit_price'],
                  additionalProperties: true,
                  properties: {
                    type: {
                      oneOf: [
                        {
                          const: 'PRODUCT',
                        },
                        {
                          const: 'SERVICE',
                        },
                        {
                          const: 'RETURNABLE_CONTAINER',
                        },
                        {
                          const: 'NON_RETURNABLE_CONTAINER',
                        },
                        {
                          const: 'PACKAGING',
                        },
                        {
                          const: 'OTHER',
                        },
                      ],
                    },
                    identifiers: {
                      type: 'object',
                      additionalProperties: true,
                      properties: {
                        gtin: {
                          type: 'string',
                        },
                        sender_internal: {
                          type: 'string',
                        },
                        receiver_internal: {
                          type: 'string',
                        },
                      },
                    },
                    name: {
                      type: 'string',
                    },
                    unit_of_measure: {
                      type: 'string',
                      default: 'EA',
                    },
                    unit_price: {
                      type: 'number',
                      minimum: 0,
                    },
                    tax_percentage: {
                      type: 'number',
                      minimum: 0,
                    },
                    tax_type: {
                      oneOf: [
                        {
                          const: 'VAT',
                        },
                        {
                          const: 'EXEMPT',
                        },
                        {
                          const: 'ZERO_RATED',
                        },
                      ],
                    },
                    net_weight: {
                      type: 'number',
                    },
                    gross_weight: {
                      type: 'number',
                    },
                    origin_country_code: {
                      type: 'string',
                    },
                    color_code: {
                      type: 'string',
                    },
                    color_name: {
                      type: 'string',
                    },
                    size: {
                      type: 'string',
                    },
                    dimension: {
                      type: 'string',
                    },
                    hs_code: {
                      type: 'string',
                    },
                    custom_fields: {
                      type: 'object',
                      additionalProperties: {
                        type: 'string',
                      },
                    },
                  },
                },
                ordered_quantity: {
                  type: 'number',
                  minimum: 0,
                },
                invoiced_quantity: {
                  type: 'number',
                  minimum: 0,
                },
                free_quantity: {
                  type: 'number',
                  minimum: 0,
                },
                line_gross_amount: {
                  type: 'number',
                },
                line_allowance_amount: {
                  type: 'number',
                },
                line_charge_amount: {
                  type: 'number',
                },
                line_net_amount: {
                  type: 'number',
                },
                line_tax_amount: {
                  type: 'number',
                },
                line_due_amount: {
                  type: 'number',
                },
                contract_identifier: {
                  type: 'string',
                },
                promotion_identifier: {
                  type: 'string',
                },
                requested_delivery_date: {
                  type: 'number',
                },
                expected_delivery_date: {
                  type: 'number',
                },
                comments: {
                  type: 'string',
                },
                batches: {
                  type: 'array',
                  items: {
                    type: 'object',
                    additionalProperties: true,
                    properties: {
                      identifier: {
                        type: 'string',
                      },
                      quantity: {
                        type: 'number',
                        minimum: 0,
                      },
                      expiration_date: {
                        type: 'number',
                      },
                      gross_weight: {
                        type: 'number',
                      },
                      net_weight: {
                        type: 'number',
                      },
                      custom_fields: {
                        type: 'object',
                        additionalProperties: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
                modification_groups: {
                  type: 'array',
                  items: {
                    type: 'object',
                    additionalProperties: true,
                    properties: {
                      basis: {
                        type: 'number',
                      },
                      comments: {
                        type: 'string',
                      },
                      charges: {
                        type: 'array',
                        items: {
                          type: 'object',
                          required: ['type'],
                          additionalProperties: true,
                          properties: {
                            type: {
                              default: 'RELATIVE',
                              oneOf: [
                                {
                                  const: 'RELATIVE',
                                },
                                {
                                  const: 'ABSOLUTE',
                                },
                              ],
                            },
                            description: {
                              type: 'string',
                            },
                            reason_code: {
                              default: 'SHIPPING',
                              oneOf: [
                                {
                                  const: 'SHIPPING',
                                },
                                {
                                  const: 'PACKAGING',
                                },
                                {
                                  const: 'HANDLING',
                                },
                                {
                                  const: 'DISCOUNT',
                                },
                              ],
                            },
                            percentage: {
                              type: 'number',
                              minimum: 0,
                            },
                            amount: {
                              type: 'number',
                              minimum: 0,
                            },
                            tax: {
                              type: 'object',
                              additionalProperties: true,
                              properties: {
                                total_tax_amount: {
                                  type: 'number',
                                },
                                items: {
                                  type: 'array',
                                  items: {
                                    type: 'object',
                                    additionalProperties: true,
                                    properties: {
                                      taxable_amount: {
                                        type: 'number',
                                      },
                                      tax_amount: {
                                        type: 'number',
                                        minimum: 0,
                                      },
                                      tax_percentage: {
                                        type: 'number',
                                        minimum: 0,
                                      },
                                      tax_type: {
                                        oneOf: [
                                          {
                                            const: 'VAT',
                                          },
                                          {
                                            const: 'EXEMPT',
                                          },
                                          {
                                            const: 'ZERO_RATED',
                                          },
                                        ],
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            custom_fields: {
                              type: 'object',
                              additionalProperties: {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                      allowances: {
                        type: 'array',
                        items: {
                          type: 'object',
                          required: ['type'],
                          additionalProperties: true,
                          properties: {
                            type: {
                              default: 'RELATIVE',
                              oneOf: [
                                {
                                  const: 'RELATIVE',
                                },
                                {
                                  const: 'ABSOLUTE',
                                },
                              ],
                            },
                            description: {
                              type: 'string',
                            },
                            reason_code: {
                              oneOf: [
                                {
                                  const: 'SHIPPING',
                                },
                                {
                                  const: 'PACKAGING',
                                },
                                {
                                  const: 'HANDLING',
                                },
                                {
                                  const: 'DISCOUNT',
                                },
                              ],
                              default: 'SHIPPING',
                            },
                            percentage: {
                              type: 'number',
                              minimum: 0,
                            },
                            amount: {
                              type: 'number',
                              minimum: 0,
                            },
                            tax: {
                              type: 'object',
                              additionalProperties: true,
                              properties: {
                                total_tax_amount: {
                                  type: 'number',
                                },
                                items: {
                                  type: 'array',
                                  items: {
                                    type: 'object',
                                    additionalProperties: true,
                                    properties: {
                                      taxable_amount: {
                                        type: 'number',
                                      },
                                      tax_amount: {
                                        type: 'number',
                                        minimum: 0,
                                      },
                                      tax_percentage: {
                                        type: 'number',
                                        minimum: 0,
                                      },
                                      tax_type: {
                                        oneOf: [
                                          {
                                            const: 'VAT',
                                          },
                                          {
                                            const: 'EXEMPT',
                                          },
                                          {
                                            const: 'ZERO_RATED',
                                          },
                                        ],
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            custom_fields: {
                              type: 'object',
                              additionalProperties: {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                      custom_fields: {
                        type: 'object',
                        additionalProperties: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
                line_items: {
                  type: 'array',
                  items: {
                    type: 'object',
                    required: ['item', 'invoiced_quantity'],
                    additionalProperties: true,
                    properties: {
                      invoice_line_identifier: {
                        type: 'string',
                      },
                      shipping_notice_line_identifier: {
                        type: 'string',
                      },
                      order_line_identifier: {
                        type: 'string',
                      },
                      shipping_notice_identifier: {
                        type: 'string',
                      },
                      shipping_notice_date: {
                        type: 'number',
                      },
                      order_identifier: {
                        type: 'string',
                      },
                      order_date: {
                        type: 'number',
                      },
                      item: {
                        type: 'object',
                        required: ['type', 'unit_price'],
                        additionalProperties: true,
                        properties: {
                          type: {
                            oneOf: [
                              {
                                const: 'PRODUCT',
                              },
                              {
                                const: 'SERVICE',
                              },
                              {
                                const: 'RETURNABLE_CONTAINER',
                              },
                              {
                                const: 'NON_RETURNABLE_CONTAINER',
                              },
                              {
                                const: 'PACKAGING',
                              },
                              {
                                const: 'OTHER',
                              },
                            ],
                          },
                          identifiers: {
                            type: 'object',
                            additionalProperties: true,
                            properties: {
                              gtin: {
                                type: 'string',
                              },
                              sender_internal: {
                                type: 'string',
                              },
                              receiver_internal: {
                                type: 'string',
                              },
                            },
                          },
                          name: {
                            type: 'string',
                          },
                          unit_of_measure: {
                            type: 'string',
                            default: 'EA',
                          },
                          unit_price: {
                            type: 'number',
                            minimum: 0,
                          },
                          tax_percentage: {
                            type: 'number',
                            minimum: 0,
                          },
                          tax_type: {
                            oneOf: [
                              {
                                const: 'VAT',
                              },
                              {
                                const: 'EXEMPT',
                              },
                              {
                                const: 'ZERO_RATED',
                              },
                            ],
                          },
                          net_weight: {
                            type: 'number',
                          },
                          gross_weight: {
                            type: 'number',
                          },
                          origin_country_code: {
                            type: 'string',
                          },
                          color_code: {
                            type: 'string',
                          },
                          color_name: {
                            type: 'string',
                          },
                          size: {
                            type: 'string',
                          },
                          dimension: {
                            type: 'string',
                          },
                          hs_code: {
                            type: 'string',
                          },
                          custom_fields: {
                            type: 'object',
                            additionalProperties: {
                              type: 'string',
                            },
                          },
                        },
                      },
                      ordered_quantity: {
                        type: 'number',
                        minimum: 0,
                      },
                      invoiced_quantity: {
                        type: 'number',
                        minimum: 0,
                      },
                      free_quantity: {
                        type: 'number',
                        minimum: 0,
                      },
                      line_gross_amount: {
                        type: 'number',
                      },
                      line_allowance_amount: {
                        type: 'number',
                      },
                      line_charge_amount: {
                        type: 'number',
                      },
                      line_net_amount: {
                        type: 'number',
                      },
                      line_tax_amount: {
                        type: 'number',
                      },
                      line_due_amount: {
                        type: 'number',
                      },
                      contract_identifier: {
                        type: 'string',
                      },
                      promotion_identifier: {
                        type: 'string',
                      },
                      requested_delivery_date: {
                        type: 'number',
                      },
                      expected_delivery_date: {
                        type: 'number',
                      },
                      comments: {
                        type: 'string',
                      },
                      batches: {
                        type: 'array',
                        items: {
                          type: 'object',
                          additionalProperties: true,
                          properties: {
                            identifier: {
                              type: 'string',
                            },
                            quantity: {
                              type: 'number',
                              minimum: 0,
                            },
                            expiration_date: {
                              type: 'number',
                            },
                            gross_weight: {
                              type: 'number',
                            },
                            net_weight: {
                              type: 'number',
                            },
                            custom_fields: {
                              type: 'object',
                              additionalProperties: {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                      modification_groups: {
                        type: 'array',
                        items: {
                          type: 'object',
                          additionalProperties: true,
                          properties: {
                            basis: {
                              type: 'number',
                            },
                            comments: {
                              type: 'string',
                            },
                            charges: {
                              type: 'array',
                              items: {
                                type: 'object',
                                required: ['type'],
                                additionalProperties: true,
                                properties: {
                                  type: {
                                    default: 'RELATIVE',
                                    oneOf: [
                                      {
                                        const: 'RELATIVE',
                                      },
                                      {
                                        const: 'ABSOLUTE',
                                      },
                                    ],
                                  },
                                  description: {
                                    type: 'string',
                                  },
                                  reason_code: {
                                    default: 'SHIPPING',
                                    oneOf: [
                                      {
                                        const: 'SHIPPING',
                                      },
                                      {
                                        const: 'PACKAGING',
                                      },
                                      {
                                        const: 'HANDLING',
                                      },
                                      {
                                        const: 'DISCOUNT',
                                      },
                                    ],
                                  },
                                  percentage: {
                                    type: 'number',
                                    minimum: 0,
                                  },
                                  amount: {
                                    type: 'number',
                                    minimum: 0,
                                  },
                                  tax: {
                                    type: 'object',
                                    additionalProperties: true,
                                    properties: {
                                      total_tax_amount: {
                                        type: 'number',
                                      },
                                      items: {
                                        type: 'array',
                                        items: {
                                          type: 'object',
                                          additionalProperties: true,
                                          properties: {
                                            taxable_amount: {
                                              type: 'number',
                                            },
                                            tax_amount: {
                                              type: 'number',
                                              minimum: 0,
                                            },
                                            tax_percentage: {
                                              type: 'number',
                                              minimum: 0,
                                            },
                                            tax_type: {
                                              oneOf: [
                                                {
                                                  const: 'VAT',
                                                },
                                                {
                                                  const: 'EXEMPT',
                                                },
                                                {
                                                  const: 'ZERO_RATED',
                                                },
                                              ],
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                  custom_fields: {
                                    type: 'object',
                                    additionalProperties: {
                                      type: 'string',
                                    },
                                  },
                                },
                              },
                            },
                            allowances: {
                              type: 'array',
                              items: {
                                type: 'object',
                                required: ['type'],
                                additionalProperties: true,
                                properties: {
                                  type: {
                                    default: 'RELATIVE',
                                    oneOf: [
                                      {
                                        const: 'RELATIVE',
                                      },
                                      {
                                        const: 'ABSOLUTE',
                                      },
                                    ],
                                  },
                                  description: {
                                    type: 'string',
                                  },
                                  reason_code: {
                                    oneOf: [
                                      {
                                        const: 'SHIPPING',
                                      },
                                      {
                                        const: 'PACKAGING',
                                      },
                                      {
                                        const: 'HANDLING',
                                      },
                                      {
                                        const: 'DISCOUNT',
                                      },
                                    ],
                                    default: 'SHIPPING',
                                  },
                                  percentage: {
                                    type: 'number',
                                    minimum: 0,
                                  },
                                  amount: {
                                    type: 'number',
                                    minimum: 0,
                                  },
                                  tax: {
                                    type: 'object',
                                    additionalProperties: true,
                                    properties: {
                                      total_tax_amount: {
                                        type: 'number',
                                      },
                                      items: {
                                        type: 'array',
                                        items: {
                                          type: 'object',
                                          additionalProperties: true,
                                          properties: {
                                            taxable_amount: {
                                              type: 'number',
                                            },
                                            tax_amount: {
                                              type: 'number',
                                              minimum: 0,
                                            },
                                            tax_percentage: {
                                              type: 'number',
                                              minimum: 0,
                                            },
                                            tax_type: {
                                              oneOf: [
                                                {
                                                  const: 'VAT',
                                                },
                                                {
                                                  const: 'EXEMPT',
                                                },
                                                {
                                                  const: 'ZERO_RATED',
                                                },
                                              ],
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                  custom_fields: {
                                    type: 'object',
                                    additionalProperties: {
                                      type: 'string',
                                    },
                                  },
                                },
                              },
                            },
                            custom_fields: {
                              type: 'object',
                              additionalProperties: {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                      line_items: {
                        type: 'array',
                        maxItems: 0,
                      },
                      custom_fields: {
                        type: 'object',
                        additionalProperties: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
                custom_fields: {
                  type: 'object',
                  additionalProperties: {
                    type: 'string',
                  },
                },
              },
            },
          },
          custom_fields: {
            type: 'object',
            additionalProperties: {
              type: 'string',
            },
          },
        },
      },
    },
    modification_groups: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: true,
        properties: {
          basis: {
            type: 'number',
          },
          comments: {
            type: 'string',
          },
          charges: {
            type: 'array',
            items: {
              type: 'object',
              required: ['type'],
              additionalProperties: true,
              properties: {
                type: {
                  default: 'RELATIVE',
                  oneOf: [
                    {
                      const: 'RELATIVE',
                    },
                    {
                      const: 'ABSOLUTE',
                    },
                  ],
                },
                description: {
                  type: 'string',
                },
                reason_code: {
                  default: 'SHIPPING',
                  oneOf: [
                    {
                      const: 'SHIPPING',
                    },
                    {
                      const: 'PACKAGING',
                    },
                    {
                      const: 'HANDLING',
                    },
                    {
                      const: 'DISCOUNT',
                    },
                  ],
                },
                percentage: {
                  type: 'number',
                  minimum: 0,
                },
                amount: {
                  type: 'number',
                  minimum: 0,
                },
                tax: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    total_tax_amount: {
                      type: 'number',
                    },
                    items: {
                      type: 'array',
                      items: {
                        type: 'object',
                        additionalProperties: true,
                        properties: {
                          taxable_amount: {
                            type: 'number',
                          },
                          tax_amount: {
                            type: 'number',
                            minimum: 0,
                          },
                          tax_percentage: {
                            type: 'number',
                            minimum: 0,
                          },
                          tax_type: {
                            oneOf: [
                              {
                                const: 'VAT',
                              },
                              {
                                const: 'EXEMPT',
                              },
                              {
                                const: 'ZERO_RATED',
                              },
                            ],
                          },
                        },
                      },
                    },
                  },
                },
                custom_fields: {
                  type: 'object',
                  additionalProperties: {
                    type: 'string',
                  },
                },
              },
            },
          },
          allowances: {
            type: 'array',
            items: {
              type: 'object',
              required: ['type'],
              additionalProperties: true,
              properties: {
                type: {
                  default: 'RELATIVE',
                  oneOf: [
                    {
                      const: 'RELATIVE',
                    },
                    {
                      const: 'ABSOLUTE',
                    },
                  ],
                },
                description: {
                  type: 'string',
                },
                reason_code: {
                  oneOf: [
                    {
                      const: 'SHIPPING',
                    },
                    {
                      const: 'PACKAGING',
                    },
                    {
                      const: 'HANDLING',
                    },
                    {
                      const: 'DISCOUNT',
                    },
                  ],
                  default: 'SHIPPING',
                },
                percentage: {
                  type: 'number',
                  minimum: 0,
                },
                amount: {
                  type: 'number',
                  minimum: 0,
                },
                tax: {
                  type: 'object',
                  additionalProperties: true,
                  properties: {
                    total_tax_amount: {
                      type: 'number',
                    },
                    items: {
                      type: 'array',
                      items: {
                        type: 'object',
                        additionalProperties: true,
                        properties: {
                          taxable_amount: {
                            type: 'number',
                          },
                          tax_amount: {
                            type: 'number',
                            minimum: 0,
                          },
                          tax_percentage: {
                            type: 'number',
                            minimum: 0,
                          },
                          tax_type: {
                            oneOf: [
                              {
                                const: 'VAT',
                              },
                              {
                                const: 'EXEMPT',
                              },
                              {
                                const: 'ZERO_RATED',
                              },
                            ],
                          },
                        },
                      },
                    },
                  },
                },
                custom_fields: {
                  type: 'object',
                  additionalProperties: {
                    type: 'string',
                  },
                },
              },
            },
          },
          custom_fields: {
            type: 'object',
            additionalProperties: {
              type: 'string',
            },
          },
        },
      },
    },
    gross_amount: {
      type: 'number',
    },
    allowance_amount: {
      type: 'number',
    },
    charge_amount: {
      type: 'number',
    },
    net_amount: {
      type: 'number',
    },
    tax: {
      type: 'object',
      additionalProperties: true,
      properties: {
        total_tax_amount: {
          type: 'number',
        },
        items: {
          type: 'array',
          items: {
            type: 'object',
            additionalProperties: true,
            properties: {
              taxable_amount: {
                type: 'number',
              },
              tax_amount: {
                type: 'number',
                minimum: 0,
              },
              tax_percentage: {
                type: 'number',
                minimum: 0,
              },
              tax_type: {
                oneOf: [
                  {
                    const: 'VAT',
                  },
                  {
                    const: 'EXEMPT',
                  },
                  {
                    const: 'ZERO_RATED',
                  },
                ],
              },
            },
          },
        },
      },
    },
    due_amount: {
      type: 'number',
    },
    custom_fields: {
      type: 'object',
      additionalProperties: {
        type: 'string',
      },
    },
  },
};
