import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { useWebEDIErrors, useWebEDIFieldError, WebEDIError } from 'services/webediErrors/webediErrors';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { ErrorIcon } from '../components/Icons';
import { CellProps } from './types';
import { useCellClassNames, useCellNestedLevel, useOptionalConfigurations } from './cellHelpers';
import { ui } from '@procuros/datachecks';
import { DisplayModes } from '../Types';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { NestedLevelAddon } from './components/NestedLevelAddon/NestedLevelAddon';
import { useValue } from 'components/WebEDI/helpers';
import { cloneElement, useEffect } from 'react';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';

export type Props<TData, TValue> = CellContext<TData, TValue> &
  CellProps & {
    error?: Omit<WebEDIError, 'id'>;
  };

export const ViewCell = <TData = unknown, TValue = string>({
  row,
  cell,
  column,
  table,
  isHighlighted,
  fieldId,
  mode,
}: Props<TData, TValue>) => {
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();
  const value = useValue(row.original, fieldId, column.columnDef.meta?.optionalConfig?.expression);

  const {
    columnDef: { meta: columnMeta },
  } = column;

  const { t } = useTranslation();
  const { prefix, suffix, isNumeric, formattedValue } = useOptionalConfigurations({
    meta: columnMeta,
    value,
    mode: DisplayModes.view,
    rowData: row.original,
    fieldId,
  });

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });
  const isDate = columnMeta?.type === ui.FieldTypes.date;
  const cellNestedLevel = useCellNestedLevel(row);
  const [truncatedRef, valueIsTruncated] = useIsTruncated();

  useEffect(() => {
    if (error && showFieldErrorModal) {
      setCurrentError(error);
    }
  }, [error, setCurrentError, showFieldErrorModal]);

  return (
    <>
      <div
        data-field={fieldId}
        tabIndex={error ? 0 : undefined}
        onFocus={() => setShowFieldErrorModal(true)}
        onBlur={() => setShowFieldErrorModal(false)}
        className={classNames(
          'border w-full flex justify-between gap-2 items-center px-3 outline-none',
          cellClassNames,
          {
            'text-red-700 underline border-bg-red-50 bg-red-50 py-2.5 cursor-pointer hover:bg-red-100': error,
            'py-3': !error,
            'bg-amber-100': isHighlighted,
            'border-red-500': showFieldErrorModal,
            'border-transparent': !showFieldErrorModal,
          },
        )}
        ref={refs.setReference}
      >
        {error && isNumeric ? <ErrorIcon /> : null}
        <Tooltip enabled={valueIsTruncated}>
          <TooltipTrigger>
            <span className="flex w-full items-center">
              <div
                className={classNames('flex w-full gap-x-0.5 overflow-hidden', {
                  'justify-end': isNumeric && !(columnMeta?.optionalConfig?.textAlign === 'left'),
                  'justify-start': columnMeta?.optionalConfig?.textAlign === 'left',
                  'tabular-nums': isNumeric || isDate,
                })}
                style={{ width: columnMeta?.optionalConfig?.width }}
              >
                {columnMeta?.showNestLevel && cellNestedLevel > 1 ? (
                  <span className="pr-1.5">
                    <NestedLevelAddon level={cellNestedLevel} />
                  </span>
                ) : null}
                {prefix && formattedValue ? <span className="text-gray-500">{prefix}</span> : null}
                {!formattedValue && error ? (
                  t('common:table.errors.requiredField')
                ) : formattedValue ? (
                  <span className="flex-initial truncate" ref={truncatedRef}>
                    {formattedValue}
                  </span>
                ) : (
                  <span className="text-gray-400">{EMPTY_FIELD}</span>
                )}
                {suffix && formattedValue ? <span className="text-gray-500">{suffix}</span> : null}
              </div>
              {columnMeta?.rightAddon && !error ? (
                <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
              ) : null}
            </span>
          </TooltipTrigger>
          <TooltipContent>{formattedValue}</TooltipContent>
        </Tooltip>
        {error && !isNumeric ? <ErrorIcon /> : null}
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={mode === DisplayModes.view}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
        />
      )}
    </>
  );
};
