import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { queryClient } from 'services/http/http';
import { startVersionCheck } from 'services/version/version';
import { initializeSentry } from 'support/helpers/analytics/analytics';
import './i18n';
import './index.css';
import './userWorker';
import { ENV } from 'services/environment/environment';

initializeSentry();
startVersionCheck();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <PostHogProvider
    apiKey={ENV.PUBLIC_POSTHOG_KEY}
    options={{
      api_host: ENV.PUBLIC_POSTHOG_HOST,
      person_profiles: 'identified_only',
      capture_pageview: false,
      ui_host: 'https://eu.posthog.com',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position="top-left" />
    </QueryClientProvider>
  </PostHogProvider>,
);
