import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { ProcessSpecificationDTO } from 'support/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  uiConfig: any;
  canonical: any;
  flattenData: any;
  initialFlattenData: any;
  isLoading: boolean;
  isValidating: boolean;
  processSpecification: ProcessSpecificationDTO | undefined;
};

type Actions = {
  setCanonical: (canonical: any) => void;
  setUiConfig: (uiConfig: any) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsValidating: (isValidating: boolean) => void;
  setFlattenData: (flattenData: any) => void;
  setInitialData: (initialFlattenData: any) => void;
  setProcessSpecification: (processSpecification: any) => void;
};

export const initialState: State = {
  uiConfig: [],
  canonical: undefined,
  flattenData: undefined,
  initialFlattenData: undefined,
  isLoading: true,
  isValidating: false,
  processSpecification: undefined,
};

export const useDatachecksStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,
    setInitialData: (initialFlattenData) => {
      set((state) => {
        state.initialFlattenData = initialFlattenData;
      });
    },
    setFlattenData: (flattenData) => {
      set((state) => {
        if (!isEqual(state.flattenData, flattenData)) {
          state.flattenData = flattenData;
        }
      });
    },
    setUiConfig: (uiConfig) => {
      set((state) => {
        if (!isEqual(state.uiConfig, uiConfig)) {
          state.uiConfig = uiConfig;
        }
      });
    },
    setCanonical: (canonical) => {
      set((state) => {
        if (!isEqual(state.canonical, canonical)) {
          state.canonical = canonical;
        }
      });
    },
    setIsLoading: (isLoading) => {
      set((state) => {
        state.isLoading = isLoading;
      });
    },
    setIsValidating: (isValidating) => {
      set((state) => {
        state.isValidating = isValidating;
      });
    },
    setProcessSpecification: (processSpecification) => {
      set((state) => {
        if (!isEqual(state.processSpecification, processSpecification)) {
          state.processSpecification = processSpecification;
        }
      });
    },
  })),
);

export const setIsValidating = (isValidating: boolean) => useDatachecksStore.setState({ isValidating });
export const setFlattenData = (flattenData: any) => useDatachecksStore.setState({ flattenData });
export const mergeFlattenData = (newFlattenData: any) => {
  const prevFlattenData = useDatachecksStore.getState().flattenData;
  const mergedFlattenData = merge({ ...prevFlattenData }, newFlattenData);
  if ((window as any).procuros_debug && !isEqual(prevFlattenData, newFlattenData)) {
    console.log('prevFlattenData', prevFlattenData);
    console.log('newFlattenData', newFlattenData);
  }
  setFlattenData(mergedFlattenData);
};
export const setInitialData = (initialFlattenData: any) => useDatachecksStore.setState({ initialFlattenData });
export const setUiConfig = (uiConfig: any) => useDatachecksStore.setState({ uiConfig });
export const setCanonical = (canonical: any) => useDatachecksStore.setState({ canonical });
export const setIsLoading = (isLoading: boolean) => useDatachecksStore.setState({ isLoading });
export const setProcessSpecification = (processSpecification: any) =>
  useDatachecksStore.setState({ processSpecification });
export const cleanStore = () => useDatachecksStore.setState(initialState);
