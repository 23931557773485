import { useMemo } from 'react';
import { ColumnDef, ColumnMeta, Row, RowData, Table } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import { formatCurrency, getCurrencySymbol } from 'support/helpers/currency/currency';
import { formatDateToISO, formatDay } from 'support/helpers/dateTime/dateTime';
import { ui } from '@procuros/datachecks';
import { useDocumentCurrency, useEnumerableOptions } from 'components/WebEDI/helpers';
import { ColumnTypesInternal, DisplayModes } from '../Types';
import { logError } from 'services/logging/logging';
import get from 'lodash/get';
import { t } from 'i18next';

export const convertTanstackIdsToDotDonation = <T extends string | undefined>(id: T): T => {
  return id?.replace(/\[(\d+)\]\./g, '.$1.') as T;
};

export const getFieldId = <TData = unknown, TValue = unknown>(
  columnDef: ColumnDef<TData, TValue>,
  dataPrefix: string | undefined,
  cellId: string,
  isRelativeModification?: boolean,
): string => {
  const accessorKey = 'accessorKey' in columnDef ? (columnDef.accessorKey as string) : undefined;
  ///modification_groups[0] to modifications_groups.0 so we can understand better the paths
  let dotNotationCellId = convertTanstackIdsToDotDonation(cellId);
  const dotNonationAccessorKey = convertTanstackIdsToDotDonation(accessorKey);
  const excludedReplacements = dotNonationAccessorKey?.split('.');

  if (isRelativeModification) {
    dotNotationCellId = dotNotationCellId.replace('amount', 'percentage');
  }
  const prefix = dataPrefix ? `${dataPrefix}.` : '';
  return `${prefix}${cellIdToFieldId(dotNotationCellId, excludedReplacements)}`;
};

const cellIdToFieldId = (cellId: string, excludedReplacements?: Array<string>): string => {
  let fieldId = cellId.replace(/_/g, '.');
  excludedReplacements?.forEach((replacement) => {
    fieldId = fieldId.replace(replacement.replaceAll('_', '.'), replacement);
  });
  return fieldId;
};

type CellClassNamesProps = {
  row: Pick<Row<RowData>, 'index'>;
  table: Pick<Table<RowData>, 'getRowCount'>;
  isFirstColumn: boolean;
  isLastColumn: boolean;
};

export const useCellClassNames = ({ row, table, isFirstColumn, isLastColumn }: CellClassNamesProps): string => {
  const totalRows = table.getRowCount();
  return useMemo(() => {
    const isLastRow = totalRows - 1 === row.index;

    return classNames({
      'rounded-bl-md': isFirstColumn && isLastRow,
      'rounded-br-md': isLastColumn && isLastRow,
    });
  }, [totalRows, row.index, isFirstColumn, isLastColumn]);
};

type OptionalConfigurationsProps = {
  meta: ColumnMeta<RowData, unknown> | undefined;
  value?: unknown;
  mode: DisplayModes;
  fieldId?: string;
  rowData?: RowData;
};

type OptionalConfigurationsResult = {
  isNumeric: boolean;
  prefix: string;
  suffix: string;
  formattedValue: any;
};

export const useOptionalConfigurations = ({
  meta,
  value,
  mode,
  fieldId,
  rowData,
}: OptionalConfigurationsProps): OptionalConfigurationsResult => {
  const type = meta?.type;
  const isNumeric = !!type && [ui.FieldTypes.monetary, ui.FieldTypes.number].includes(type as ui.FieldTypes);
  const options = useEnumerableOptions(meta?.optionalConfig);
  const currency = useDocumentCurrency({ disabled: !isMonetaryField(meta) });
  const translateValue = meta?.optionalConfig?.translateValue || ((value: any) => value);

  const { formattedValue, prefix, suffix } = formatField(type, meta, fieldId, value, currency, mode, rowData, options);

  return { isNumeric, prefix, suffix, formattedValue: translateValue(formattedValue) };
};

const isModificationAmount = (fieldId: string | undefined): boolean => {
  if (!fieldId) return false;
  return (
    fieldId.includes('modification_groups') &&
    (fieldId.includes('allowances') || fieldId.includes('charges')) &&
    (fieldId.includes('percentage') || fieldId.includes('amount'))
  );
};

const formatMonetaryField = (value: any, currency: string, isPercentage: boolean, mode: DisplayModes) => {
  let formattedValue = '';
  let prefix = '';
  const suffix = isPercentage ? '%' : '';

  try {
    if (mode === DisplayModes.create || mode === DisplayModes.edit) {
      prefix = isPercentage ? '' : getCurrencySymbol(currency);
    }
    if (!isPercentage) {
      formattedValue = value ? formatCurrency(parseFloat(value as string), currency) : '';
    } else {
      formattedValue = typeof value === 'number' && isNaN(value) ? undefined : value;
    }
  } catch (e) {
    logError(e);
    prefix = '';
  }

  return { formattedValue, prefix, suffix };
};

const formatNumberField = (value: any, meta: any, isPercentage: boolean, mode: DisplayModes) => {
  const prefix = isPercentage ? '' : meta.optionalConfig?.prefix ?? '';
  const suffix = isPercentage ? '%' : meta.optionalConfig?.suffix ?? '';
  let formattedValue = typeof value === 'number' && isNaN(value) ? undefined : value;

  if (mode === DisplayModes.view) {
    formattedValue = formattedValue == undefined ? '' : String(formattedValue);
  }

  return { formattedValue, prefix, suffix };
};

const formatField = (
  type: ui.FieldTypes | undefined | ColumnTypesInternal,
  meta: any,
  fieldId: string | undefined,
  value: any,
  currency: string,
  mode: DisplayModes,
  rowData: any,
  options: Array<any>,
) => {
  let formattedValue = value;
  let prefix = '';
  let suffix = '';
  const isPercentage =
    isModificationAmount(fieldId) &&
    isRelativeModification(fieldId, rowData, mode, Boolean(fieldId?.includes('line_items')));
  switch (type) {
    case ui.FieldTypes.monetary:
      if (isMonetaryField(meta)) {
        ({ formattedValue, prefix, suffix } = formatMonetaryField(value, currency, isPercentage, mode));
      }
      break;

    case ui.FieldTypes.number:
      if (isNumberField(meta)) {
        ({ formattedValue, prefix, suffix } = formatNumberField(value, meta, isPercentage, mode));
      }
      break;

    case ui.FieldTypes.date:
      formattedValue = formatDateValue(value, mode);
      break;

    case ui.FieldTypes.select:
      formattedValue = options?.find((option) => option.value === value)?.label ?? value;
      break;

    case ui.FieldTypes.boolean:
      formattedValue = value ? t('common:yes') : t('common:no');
      break;

    default:
      break;
  }

  return { formattedValue, prefix, suffix };
};

const isMonetaryField = (
  field: ColumnMeta<RowData, unknown> | undefined,
): field is { type: ui.FieldTypes.number; optionalConfig: ui.MonetaryField['type_config'] } => {
  return field?.type === ui.FieldTypes.monetary;
};

const isNumberField = (
  field: ColumnMeta<RowData, unknown> | undefined,
): field is { type: ui.FieldTypes.number; optionalConfig: ui.NumberField['type_config'] } => {
  return field?.type === ui.FieldTypes.number;
};

export const useProcurosAddedValue = <TData>(row: Row<TData>): boolean => {
  return Boolean(get(row.original, `_internal.${ColumnTypesInternal.procuros_added}`));
};

export const useCellNestedLevel = <TData>(row: Row<TData>): number => {
  return get(row.original, `_internal.level`) as number;
};

const formatDateValue = (value: unknown, mode: DisplayModes) => {
  let date;

  if (value instanceof Date) {
    date = value;
  } else if (typeof value === 'string' || typeof value === 'number') {
    const timestamp = typeof value === 'string' ? parseInt(value) : value;
    date = new Date(timestamp * 1000);
  }

  if (date && !isNaN(date.getTime())) {
    const isoDate = formatDateToISO(date);
    return mode === DisplayModes.create || mode === DisplayModes.edit ? isoDate : formatDay(isoDate);
  }

  return undefined;
};

export const isRelativeModification = (
  fieldId: string | undefined,
  rowData: any,
  mode: DisplayModes,
  isLineItems: boolean,
) => {
  const isViewMode = mode === DisplayModes.view;
  if (!fieldId || !rowData) {
    return false;
  }
  if (!isLineItems) {
    return Boolean(rowData.type === 'RELATIVE' || (isViewMode && rowData.percentage));
  } else {
    if (fieldId.includes('allowances')) {
      return (
        get(rowData, 'modification_groups.0.allowances.0.type') === 'RELATIVE' ||
        (isViewMode && get(rowData, 'modification_groups.0.allowances.0.percentage'))
      );
    }
    if (fieldId.includes('charges')) {
      console.log;
      return (
        get(rowData, 'modification_groups.0.charges.0.type') === 'RELATIVE' ||
        (isViewMode && get(rowData, 'modification_groups.0.charges.0.percentage'))
      );
    }
  }
  return false;
};
