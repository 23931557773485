import { TradePartnerStats, TransactionGraphData } from 'pages/Dashboard/DashboardWithGraphs/types';

import portalSchema from 'support/types/schema-portal';
import v2schema from 'support/types/schema-v2';
import { ValueOf } from '.';

export enum DocumentType {
  unknown = 'UNKNOWN',
  order = 'ORDER',
  orderResponse = 'ORDER_RESPONSE',
  shippingNotice = 'SHIPPING_NOTICE',
  invoice = 'INVOICE',
  creditNote = 'CREDIT_NOTE',
  dispatchInstruction = 'DISPATCH_INSTRUCTION',
  dispatchResponse = 'DISPATCH_INSTRUCTION_RESPONSE',
  receivalNotice = 'RECEIVAL_NOTICE',
  remittanceAdvice = 'REMITTANCE_ADVICE',
  productCatalog = 'PRODUCT_CATALOG',
}

export enum TransactionErrorType {
  internal = 'INTERNAL',
  config = 'CONFIG',
  data = 'DATA',
  notice = 'NOTICE',
}

export enum MessageState {
  canonicalized = 'canonicalized',
  decanonicalizedFailed = 'decanonicalization_failed',
  decanonicalized = 'decanonicalized',
  dropped = 'dropped',
  enriched = 'enriched',
  enrichedFailed = 'enrichment_failed',
  initial = 'initial',
  pendingCollection = 'pending_collection',
  pendingRelease = 'pending_release',
  routed = 'routed',
  routingFailed = 'routing_failed',
  validated_for_destination = 'validated_for_destination',
  validation_for_destination_failed = 'validation_for_destination_failed',
}

export enum SenderEnvelopeState {
  pendingAutoRecovery = 'pending_auto_recovery',
  canonicalizationFailed = 'canonicalization_failed',
  canonicalized = 'canonicalized',
  dropped = 'dropped',
  initial = 'initial',
  received = 'received',
  normalized = 'normalized',
  receivalFailed = 'receival_failed',
  normalizationFailed = 'normalization_failed',
  enrichementFailed = 'enrichment_failed',
  routingFailed = 'routing_failed',
}

export enum ReceiverEnvelopeState {
  pendingAutoRecovery = 'pending_auto_recovery',
  alreadySent = 'already_sent',
  decanonicalized = 'decanonicalized',
  delivered = 'delivered',
  deliveryFailed = 'delivery_failed',
  deliveryPending = 'delivery_pending',
  deliverySimulated = 'delivery_simulated',
  deliverySimulationFailed = 'delivery_simulation_failed',
  dropped = 'dropped',
  initial = 'initial',
  pendingPickup = 'pending_pickup',
  pickupFailed = 'pickup_failed',
  denormalized = 'denormalized',
  denormalizationFailed = 'denormalization_failed',
  manuallyDelivered = 'manually_delivered',
}

export const InternalTransactionState = {
  ...SenderEnvelopeState,
  ...MessageState,
  ...ReceiverEnvelopeState,
};
export type InternalTransactionStateType = ValueOf<
  (typeof InternalTransactionState)[keyof typeof InternalTransactionState]
>;

export type ErrorTypeEnum = 'INTERNAL' | 'CONFIG' | 'DATA' | 'NOTICE';

export type AddressIdDomain = portalSchema.components['schemas']['AddressIdDomain'];

export type UnitOfMeasure = 'EA' | 'GRM' | 'HUR' | 'KGM' | 'LTR' | 'MLT' | 'MTK' | 'MTR' | 'PCK' | 'SET';

export enum CustomerStatus {
  active = 'Active',
  requested = 'Requested',
  blocked = 'Blocked',
  inTesting = 'In testing',
  inactive = 'Inactive',
  readyToTest = 'Ready to test',
}

export enum TransactionState {
  failed = 'FAILED',
  success = 'SUCCESS',
  pending = 'PENDING',
  dropped = 'DROPPED',
  unknown = 'UNKNOWN',
}

export enum TransactionFlowState {
  live = 'LIVE',
  test = 'TEST',
}

export enum RelationshipPartnerStatus {
  connected = 'connected',
  pending = 'pending',
  notConnected = 'not_connected',
}
export enum RelationshipStatus {
  PARTNER_APPROVAL_PENDING = 'PARTNER_APPROVAL_PENDING',
  PARTNER_ONBOARDING_PENDING = 'PARTNER_ONBOARDING_PENDING',
  PARTNER_INTEGRATION_PENDING = 'PARTNER_INTEGRATION_PENDING',
  PARTNER_PROCESS_PENDING = 'PARTNER_PROCESS_PENDING',
  ISSUE_RESOLVER_PENDING = 'ISSUE_RESOLVER_PENDING',
  LIVE_ORDER_NEEDED = 'LIVE_ORDER_NEEDED',
  READY_FOR_TESTING = 'READY_FOR_TESTING',
  HAS_TEST_MESSAGES = 'HAS_TEST_MESSAGES',
  IN_REVIEW = 'IN_REVIEW',
  GO_LIVE_DATE_PENDING = 'GO_LIVE_DATE_PENDING',
  GO_LIVE_DATE_SCHEDULED = 'GO_LIVE_DATE_SCHEDULED',
  ACTIVE = 'ACTIVE',
  LIVE = 'LIVE',
  REJECTED = 'REJECTED',
  DEACTIVATED = 'DEACTIVATED',
  BLOCKED = 'BLOCKED',
}

export enum TransactionInternalType {
  message = 'message',
  senderEnvelope = 'sender-envelope',
}

export type UnitOfMeasureType = v2schema.components['schemas']['UnitOfMeasure'];
export type ItemIdentifier = v2schema.components['schemas']['ItemIdentifier'];
export type ItemIdentifierDomain = v2schema.components['schemas']['ItemIdentifier']['domain'];
export type PartyDTO = v2schema.components['schemas']['Party'];
export type PostalAddressDTO = v2schema.components['schemas']['PostalAddress'];
export type PartyIdentifierDomain = v2schema.components['schemas']['PartyIdentifier']['domain'];

export type OrderDTO = v2schema.components['schemas']['Order'];
export type OrderItemDTO = v2schema.components['schemas']['OrderItem'];

export type OrderResponseDTO = v2schema.components['schemas']['OrderResponse'];
export type OrderResponseItemDTO = v2schema.components['schemas']['OrderResponseItem'];

export type ProductCatalogDTO = v2schema.components['schemas']['ProductCatalog'];
export type ProductCatalogItemDTO = v2schema.components['schemas']['ProductCatalogItem'];

export type ShippingNoticeDTO = v2schema.components['schemas']['ShippingNotice'];
export type ShippingNoticeTransportUnitDTO = v2schema.components['schemas']['ShippingNoticeTransportUnit'];
export type ShippingNoticeItemDTO = v2schema.components['schemas']['ShippingNoticeItem'];

export type DispatchInstructionDTO = v2schema.components['schemas']['DispatchInstruction'];

export type DispatchInstructionResponseDTO = v2schema.components['schemas']['DispatchInstructionResponse'];
export type DispatchInstructionResponseItemDTO = v2schema.components['schemas']['DispatchInstructionResponseItem'];

export type InvoiceDTO = v2schema.components['schemas']['Invoice'];
export type InvoiceItemDTO = v2schema.components['schemas']['InvoiceItem'];
export type InvoiceAndCreditNoteSummaryDTO = v2schema.components['schemas']['Invoice']['summary'];
export type ModificationGroupDTO = v2schema.components['schemas']['ModificationGroup'];
export type ModificationDTO = v2schema.components['schemas']['Modification'];
export type ModificationType = v2schema.components['schemas']['Modification']['type'];
export type ModificationReasonCode = v2schema.components['schemas']['Modification']['reasonCode'];
export type PaymentTermDTO = v2schema.components['schemas']['PaymentTerm'];

export type CreditNoteDTO = v2schema.components['schemas']['CreditNote'];

export type TransactionPayloadDTO = v2schema.components['schemas']['TransactionContent'];
export type TransactionType = v2schema.components['schemas']['TransactionType'];

export type MessageDTO = portalSchema.components['schemas']['Message'] & {
  jsonPayload: TransactionPayloadDTO;
};

export type MessageV2DTO = portalSchema.components['schemas']['MessageV2'];

export type NewTransactionDTO<T> = {
  type: TransactionType;
  content: T;
};

export type RawTransactionDTO = portalSchema.components['schemas']['TransactionListItem'];
export type TransactionDTO = RawTransactionDTO & {
  internalType: TransactionInternalType;
};

export type SenderEnvelopeDTO = portalSchema.components['schemas']['SenderEnvelope'];
export type SenderEnvelopeV2DTO = portalSchema.components['schemas']['SenderEnvelopeV2'];

export type ReceiverEnvelopeDTO = portalSchema.components['schemas']['ReceiverEnvelope'];

export type IntegrationDTO = portalSchema.components['schemas']['Integration'];
export type IntegrationConnectivityTestDTO = portalSchema.components['schemas']['ConnectivityTest'];

export type IntegrationProcessDTO = portalSchema.components['schemas']['Process'];
export type IntegrationProcessTestDTO = portalSchema.components['schemas']['ProcessTest'];

export type TemplateDTO = portalSchema.components['schemas']['Template'];
export type SchemaDTO = portalSchema.components['schemas']['Schema'];
export type TransformationDTO = portalSchema.components['schemas']['Transformation'];

export type AS2ConfigurationDTO = portalSchema.components['schemas']['AS2Configuration'];

export type EDIFACTSyntaxIdentifier =
  portalSchema.components['schemas']['AS2Configuration']['edifact_syntax_identifier'];

export type EDIFACTSyntaxVersion = portalSchema.components['schemas']['AS2Configuration']['edifact_syntax_version'];

export type RelationshipDTO = portalSchema.components['schemas']['Relationship'];
export type RelationshipV2 = portalSchema.components['schemas']['RelationshipV2'];
export type RelationshipDocumentStatus = portalSchema.components['schemas']['RelationshipDocumentStatus'];

export type UserDTO = portalSchema.components['schemas']['User'];
export type VersionLogDTO = portalSchema.components['schemas']['VersionLog'];

export type PartnerDTO = portalSchema.components['schemas']['Partner'];
export type PartnerV2 = portalSchema.components['schemas']['PartnerV2'];

export type PartnerDocumentTypes = portalSchema.components['schemas']['PartnerDocumentTypes'];
export type PartnerDocumentTypeItem = portalSchema.components['schemas']['PartnerDocumentTypeItem'];

export type PartnerSettingsDTO = portalSchema.components['schemas']['PartnerSettings'];

export type InviteMemberDTO = portalSchema.components['schemas']['InviteMember'];
export type UpdatePartnerDTO = portalSchema.components['schemas']['UpdatePartner'];

export type InboxStatsDTO = portalSchema.components['schemas']['InboxStats'];

export type PricingConfigDTO = portalSchema.components['schemas']['PricingConfig'];
export type PricingConfigPaymentTermsDTO = portalSchema.components['schemas']['PricingConfigPaymentTerms'];
export type PricingConfigPayloadLineItemDTO = portalSchema.components['schemas']['PricingConfigPayloadLineItem'];
export type PricingConfigModificationDTO = portalSchema.components['schemas']['PricingConfigModification'];

export type FailedTransactionCountDTO = {
  failedTransactions: number;
};

export type DashboardStatsDTO = {
  transactions: TransactionGraphData;
  tradePartnerStats: TradePartnerStats;
};

export type PostalAddress = {
  name: string | null;
  street: string;
  city: string;
  postalCode: string | null;
  country: string | null;
  addressExtra: string | null;
};

export type Address = {
  gln: string;
  name: string;
  postalAddress: PostalAddress | null;
};

export type PaginationDTO<ItemType> = {
  totalCount?: number | null;
  count: number;
  hasMore: boolean;
  currentCursor: string;
  nextCursor: string;
  previousCursor: string;
  nextPageUrl?: string;
  perPage: number;
  items: Array<ItemType>;
};

export type TransactionError = {
  id: string;
  type: ValueOf<TransactionErrorType>;
  title: string | null;
  description?: string | null;
  howToFix?: string | null;
};

export enum ProcessDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum EdifactStandard {
  D01B = 'D01B',
  D96A = 'D96A',
}

export type IIntegrationOnboarding = portalSchema.components['schemas']['Partner']['onboardingSteps'][number];
export type IOnboardingStep = NonNullable<
  IIntegrationOnboarding['required'] | IIntegrationOnboarding['optional']
>[number];

export enum IOnboardingStepState {
  NOT_STARTED = 'NOT_STARTED',
  WAITING_FOR_PROCUROS = 'WAITING_FOR_PROCUROS',
  COMPLETED = 'COMPLETED',
}

export enum IOnboardingStepKeys {
  CREATE_COMPANY = 'CREATE_COMPANY',
  INVITE_YOUR_TEAM_MEMBERS = 'INVITE_YOUR_TEAM_MEMBERS',
  SETUP_INTEGRATION = 'SETUP_INTEGRATION',
  CREATE_OR_UPLOAD_PRICE_LIST = 'CREATE_OR_UPLOAD_PRICE_LIST',
}

export type ConnectorsType = portalSchema.components['schemas']['ConnectorEnum'];
export enum Connectors {
  ALLEGRO = 'ALLEGRO',
  API_V2 = 'API_V2',
  ARIBA = 'ARIBA',
  AS2 = 'AS2',
  BYRD = 'BYRD',
  HTTPS = 'HTTPS',
  SFTP = 'SFTP',
  WECLAPP = 'WECLAPP',
  XENTRAL_V2 = 'XENTRAL_V2',
  WEB_EDI = 'WEB_EDI',
  MICROSOFT_BUSINESS_CENTRAL = 'MICROSOFT_BUSINESS_CENTRAL',
  ODOO = 'ODOO',
  HAUFE = 'HAUFE',
  NONE = 'NONE',
  EMAIL = 'EMAIL',
}

export enum LegacyConnectors {
  WEB_EDI_LEGACY = 'WEB_EDI_LEGACY',
}

export enum IdentifierDomainOptions {
  GS1 = 'GS1',
  RECEIVER_INTERNAL = 'RECEIVER_INTERNAL',
}

type ProcessDTO = portalSchema.components['schemas']['Process'];
type CanonicalFieldResponseDTO = portalSchema.components['schemas']['CanonicalFieldResponse'];
export type ProcessSpecificationDTO = {
  process: ProcessDTO;
  fields: Array<CanonicalFieldResponseDTO>;
  data: Record<string, unknown> | null | undefined;
};

export enum IssueResolverType {
  CURRENT_PARTNER = 'CURRENT_PARTNER',
  OTHER_PARTNER = 'OTHER_PARTNER',
  PROCUROS = 'PROCUROS',
}
