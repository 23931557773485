import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { WebEDIError } from 'stores/webediErrors/webediErrors';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { useEffect } from 'react';

type DataDisplayProps = {
  label: string | undefined;
  value: string | number | undefined;
  error?: WebEDIError;
  totalNumberOfErrors: number;
  fieldId: string;
  onShowFieldErrorModal: (showFieldErrorModal: boolean) => void;
};

const isRequiredError = (error: WebEDIError | undefined) => {
  if (!error) return false;
  return error.type === 'required';
};

export const DataDisplay = ({
  label,
  value,
  error,
  totalNumberOfErrors,
  fieldId,
  onShowFieldErrorModal,
}: DataDisplayProps) => {
  const { t } = useTranslation();
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();
  const valueWithFallback = value || <span className="text-gray-400">{EMPTY_FIELD} </span>;
  const buttonText = isRequiredError(error) ? t('webedi:errors.required.placeholder') : valueWithFallback;

  useEffect(() => {
    if (error && showFieldErrorModal) {
      onShowFieldErrorModal?.(true);
    }
  }, [error, onShowFieldErrorModal, showFieldErrorModal]);

  return (
    <div>
      <div className="flex flex-col gap-2">
        <span className="text-xs text-gray-500">{label}</span>
        {error ? (
          <div className="flex w-fit items-center gap-1" ref={refs.setReference}>
            <Button
              variant="text"
              id={fieldId}
              data-field={fieldId}
              className="!focus-visible:ring-0 !focus-visible:outline-none -ml-1 -mt-1.5 border border-transparent !p-1 !pr-1.5 text-sm !font-normal leading-tight text-red-700 underline !ring-0 hover:text-red-800 focus:border-red-500 focus:bg-red-50"
              onClick={() => setShowFieldErrorModal(true)}
              onFocus={() => setShowFieldErrorModal(true)}
              onBlur={() => setShowFieldErrorModal(false)}
              RightIcon={ExclamationCircleIcon}
              analyticsId="field_display_error"
            >
              {buttonText}
            </Button>
          </div>
        ) : (
          <span className="text-sm text-gray-700">{valueWithFallback}</span>
        )}
      </div>
      {showFieldErrorModal ? (
        <div>
          <MessageFieldErrorModal
            viewMode={true}
            error={error}
            numberOfErrors={totalNumberOfErrors}
            ref={refs.setFloating}
            style={floatingStyles}
          />
        </div>
      ) : null}
    </div>
  );
};
