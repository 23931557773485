import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';
import { useRegister } from 'services/repositories/user/user';
import { DashboardRoutes } from 'support/types';
import { Alert } from 'components/Display/Alert/Alert';
import { isPotentiallyAuthenticated } from 'services/auth/auth';
import { AllowedLanguages, setLanguage } from 'i18n';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { Button } from 'components/Form/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CheckboxInABox } from 'components/Form/Checkbox/Checkbox';
import { Translate } from 'components/Translate/Translate';

type UserData = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  language: AllowedLanguages;
  termsAccepted: boolean;
};
const defaultData: UserData = {
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  language: 'de' as AllowedLanguages,
  termsAccepted: false,
};

export function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const { mutate, isLoading } = useRegister();
  useEffect(() => {
    const searchParamsLanguage = searchParams.get('language');
    if (searchParamsLanguage) {
      setLanguage(searchParamsLanguage as AllowedLanguages);
    }
  }, [searchParams]);

  useEffect(() => {
    // If the user is already logged in, redirect to the dashboard
    if (isPotentiallyAuthenticated()) {
      navigate(DashboardRoutes.root, { replace: true });
    }
  }, [navigate]);

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<UserData>({
    defaultValues: {
      ...defaultData,
      ...(searchParams.get('email') ? { email: searchParams.get('email') ?? '' } : {}),
      ...(searchParams.get('name') ? { name: searchParams.get('name') ?? '' } : {}),
      ...(searchParams.get('language') ? { language: searchParams.get('language') as AllowedLanguages } : {}),
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .label(t('auth:register.name'))
          .required(({ label: field }) => t('common:form.required', { field })),
        email: yup
          .string()
          .label(t('auth:register.email'))
          .email()
          .required(({ label: field }) => t('common:form.required', { field })),
        password: yup
          .string()
          .label(t('auth:register.password'))
          .required(({ label: field }) => t('common:form.required', { field })),
        password_confirmation: yup
          .string()
          .label(t('auth:register.confirmPassword'))
          .required(({ label: field }) => t('common:form.required', { field })),
        language: yup
          .string()
          .oneOf(['en', 'de'])
          .required(({ label: field }) => t('common:form.required', { field })),
        termsAccepted: yup
          .boolean()
          .oneOf([true], t('auth:register.terms.error'))
          .required(t('auth:register.terms.error')),
      }),
    ),
  });

  const onSubmit = async (data: UserData) => {
    setNonInputErrors([]);
    const { termsAccepted, ...credentials } = data;

    const userData = {
      ...credentials,
      registration_token: searchParams.get('token') ?? '',
    };

    mutate(userData, {
      onSuccess: () => {
        setSignupSuccess(true);
      },
      onError: (err) => {
        processSubmissionErrors({
          error: err,
          setInputError: setError,
          setNonInputErrors,
          defaultData,
          t,
        });
      },
    });
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ApplicationLogo className="mx-auto h-16 w-auto bg-black" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('auth:register.heading')}</h2>
      </div>

      <div className="mt-8 bg-white px-4 py-8 shadow sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg sm:px-10">
        {signupSuccess ? (
          <Alert severity="success" title={t('auth:register.successTitle')}>
            <p>{t('auth:register.successText', { email: getValues('email') })}</p>
          </Alert>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <ValidationErrors errors={nonInputErrors} />

            <Input
              {...register('name')}
              autoComplete="name"
              label={t('auth:register.name')}
              required
              errors={errors.name}
            />

            <Input
              {...register('email')}
              autoComplete="email"
              label={t('auth:register.email')}
              type="email"
              required
              errors={errors.email}
            />

            <Input
              {...register('password')}
              autoComplete="new-password"
              type="password"
              required
              label={t('auth:register.password')}
              errors={errors.password}
            />

            <Input
              {...register('password_confirmation')}
              autoComplete="new-password"
              type="password"
              required
              label={t('auth:register.confirmPassword')}
              errors={errors.password_confirmation}
            />

            <CheckboxInABox
              {...register('termsAccepted')}
              errors={errors.termsAccepted}
              description={<Translate i18nKey="auth:register.terms.label" />}
            />
            <Button type="submit" fullWidth loading={isLoading} analyticsId="register:register">
              {t('auth:register.register')}
            </Button>

            <div className="relative mt-10">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-500">{t('auth:register.returning')}</span>
              </div>
            </div>

            <div className="mt-6">
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate('/login')}
                analyticsId="register:login_navigate"
              >
                {t('auth:register.login')}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
