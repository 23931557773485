import { EMPTY_FIELD } from 'support/helpers/const/const';

type SummaryAccordionFooterProps = {
  label: string;
  value: string | number | undefined;
};
export const SummaryAccordionFooter = ({ label, value }: SummaryAccordionFooterProps) => {
  return (
    <div className="flex w-full flex-col px-8 pb-6">
      <div className="flex items-center justify-between border-t border-gray-200 pt-4 text-sm font-bold text-gray-900">
        <span>{label}</span>
        <span>{value || EMPTY_FIELD}</span>
      </div>
    </div>
  );
};
