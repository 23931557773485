import { formatDateToISO } from 'support/helpers/dateTime/dateTime';
import {
  ModificationType,
  PricingConfigDTO,
  PricingConfigModificationDTO,
  PricingConfigPayloadLineItemDTO,
  PricingConfigPaymentTermsDTO,
} from 'support/types';

type ChargeAndDiscount = {
  'Allowance or Charge': ModificationType;
  Amount: string;
  Level: string;
  Type: 'ABSOLUTE' | 'RELATIVE';
  'Tax Percent (optional)': string;
  Code: string;
};

type PaymentTerm = {
  Percentage: string;
  'Days Due': string;
};

type LineItem = {
  GTIN: string;
  'Unit Price': string;
  'Sales Tax': string;
  Modifiable: boolean;
  'Allowance or Charge': ModificationType;
  Type: 'ABSOLUTE' | 'RELATIVE';
  Amount: string;
};

export type TemplateData = {
  'Charges and Discounts': ChargeAndDiscount[];
  'Payment Terms': PaymentTerm[];
  Products: LineItem[];
};

type NormalizedData = {
  modifications: PricingConfigModificationDTO[];
  paymentTerms: PricingConfigPaymentTermsDTO[];
  lineItems: PricingConfigPayloadLineItemDTO[];
};

const normalizeChangesAndDiscounts = (data: ChargeAndDiscount[]): PricingConfigModificationDTO[] => {
  const normalizedData: PricingConfigModificationDTO[] = data.map((chargeAndDiscount) => {
    return {
      level: parseInt(chargeAndDiscount.Level),
      type: chargeAndDiscount['Allowance or Charge'],
      code: chargeAndDiscount.Code,
      amount: parseFloat(chargeAndDiscount.Amount),
      isPercentage: chargeAndDiscount.Type === 'RELATIVE',
      taxPercent: parseFloat(chargeAndDiscount['Tax Percent (optional)']),
    };
  });

  return normalizedData;
};

const normalizePaymentTerms = (data: PaymentTerm[]): PricingConfigPaymentTermsDTO[] => {
  const normalizedData: PricingConfigPaymentTermsDTO[] = data.map((paymentTerm) => {
    return {
      payInNumberOfDays: parseInt(paymentTerm['Days Due']),
      percentage: parseFloat(paymentTerm.Percentage),
    };
  });

  return [
    {
      percentage: 0,
      payInNumberOfDays: 0,
    },
    ...normalizedData,
  ];
};

const normalizeLineItems = (data: LineItem[]): PricingConfigPayloadLineItemDTO[] => {
  const normalizedData: PricingConfigPayloadLineItemDTO[] = data.map((lineItem) => {
    return {
      gtin: lineItem.GTIN,
      unitPrice: parseFloat(lineItem['Unit Price']),
      salesTax: parseFloat(lineItem['Sales Tax']),
      modifiable: lineItem.Modifiable,
      discountOrChargeType: lineItem['Allowance or Charge'],
      discountOrChargeValue: parseFloat(lineItem.Amount),
      discountOrChargeIsPercent: lineItem.Type === 'RELATIVE',
    };
  });

  return normalizedData;
};

export const normalizeData = (data: TemplateData): PricingConfigDTO => {
  const normalizedData: NormalizedData = {
    modifications: normalizeChangesAndDiscounts(data['Charges and Discounts']),
    paymentTerms: normalizePaymentTerms(data['Payment Terms']),
    lineItems: normalizeLineItems(data['Products']),
  };

  return {
    id: '',
    name: '',
    payload: normalizedData,
    relationshipId: '',
    partner: {
      id: '',
      name: '',
    },
    createdAt: formatDateToISO(new Date()),
  };
};

export const isValid = (data: unknown): boolean => {
  if (!data) return false;
  if (typeof data !== 'object') return false;

  const hasChargesAndDiscounts = data.hasOwnProperty('Charges and Discounts');
  const hasPaymentTerms = data.hasOwnProperty('Payment Terms');
  const hasProducts = data.hasOwnProperty('Products');

  return hasChargesAndDiscounts && hasPaymentTerms && hasProducts;
};
