import * as Sentry from '@sentry/react';
import { posthog, type CaptureOptions, type Properties } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useCallback, useMemo } from 'react';
import { ENV } from 'services/environment/environment';
import { PartnerDTO, UserDTO } from 'support/types';

export const buildAnalyticsAttributes = ({ analyticsId, analyticsProperties }: AnalyticsProps) => {
  const props = Object.entries(analyticsProperties ?? {}).reduce((carry, [key, value]) => {
    const cleanKey = key.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');

    return { ...carry, [`data-ph-capture-attribute-${cleanKey}`]: value };
  }, {});

  return {
    ...props,
    'data-cheese-id': analyticsId,
  };
};

export type AnalyticsProps = {
  analyticsId: string;
  analyticsProperties?: Record<string, string>;
};

export type TrackFunction = (event: string, properties?: Properties, options?: CaptureOptions) => void;

export const useAnalytics = () => {
  const posthog = usePostHog();

  const identify = useCallback(
    (user: UserDTO, partner: PartnerDTO) => {
      posthog?.identify(user.id, {
        email: user.email,
        name: user.name,
        language: user.language,
      });
      posthog?.group('tradePartner', partner.id, {
        name: partner.name,
        industry: partner.industry,
      });

      window.Appcues.identify(
        user.id, // unique, required
        {
          firstName: user.name, // current user's first name
          email: user.email, // Current user's email
          language: user.language, // for multi-language applications
        },
      );
      window.Appcues.group(partner.id, {
        companyName: partner.name,
        industry: partner.industry,
      });
    },
    [posthog],
  );

  const reset = useCallback(() => {
    posthog?.reset();
  }, [posthog]);

  const track = useCallback(
    (event: string, properties?: Properties, options?: CaptureOptions) => {
      posthog?.capture(event, properties, options);
    },
    [posthog],
  );

  const pageView = useCallback(() => {
    track('$pageview', {
      $current_url: window.location.href,
    });
    window.Appcues.page();
  }, [track]);

  return useMemo(() => ({ identify, reset, track, pageView }), [identify, reset, track, pageView]);
};

export const isSentrySupportedEnvironment = () => {
  return ['production', 'staging', 'dev'].includes(ENV.APP_ENV ?? '');
};

export const initializeSentry = () => {
  if (isSentrySupportedEnvironment() && ENV.SENTRY_DSN) {
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        posthog.sentryIntegration({
          organization: 'procuros-gmbh',
          projectId: 4503999607799808,
          severityAllowList: ['error', 'info'], // optional: here is set to handle captureMessage (info) and captureException (error)
        }),
      ],
      beforeSend(event) {
        if (event.request && event.request.url && event.request.url.includes('/internal/')) {
          return null; // This will prevent the event from being sent to Sentry
        }
        return event; // This will allow other events to be sent
      },

      tracesSampleRate: 1.0,
      environment: ENV.SENTRY_ENVIRONMENT,
      release: ENV.SENTRY_RELEASE,
    });
  }
};
