import { t } from 'i18next';
import set from 'lodash/set';
import { ResolverResult } from 'react-hook-form';
import { getCanonicalAndErrors } from 'services/datachecks/datachecks';
import { logError } from 'services/logging/logging';
import { setIsValidating } from 'stores/datachecks/datachecks';
import { ProcessSpecificationDTO } from 'support/types';
import { GenericError } from '../errors/errors';

type DatacheckError = {
  flat_dotnotation: string;
  failed_checks: Array<{ error: { title: string; description: string } }>;
  is_hidden: boolean;
};

type ModalityError = {
  exists: boolean;
  modality: string;
  flat_dotnotation: string;
  field: { name: string };
  is_hidden: boolean;
};

// Helper functions
const setNestedError = (
  errors: Record<string, unknown>,
  path: string,
  errorType: string,
  errorMessage: string,
): void => {
  set(errors, path, { type: errorType, message: errorMessage });
};

const processDatachecksErrors = (
  errors: Record<string, unknown>,
  datachecksErrors: Array<DatacheckError>,
): Array<DatacheckError> => {
  const hiddenErrors: Array<DatacheckError> = [];
  datachecksErrors.forEach((error) => {
    if (error.is_hidden) {
      hiddenErrors.push(error);
    }
    if (error.failed_checks.length > 0 && 'flat_dotnotation' in error && !error.is_hidden) {
      setNestedError(
        errors,
        error.flat_dotnotation,
        'external',
        `[TITLE=${error.failed_checks[0].error.title}][DESCRIPTION=${error.failed_checks[0].error.description}]`,
      );
    }
  });

  return hiddenErrors;
};

const processModalityErrors = (
  errors: Record<string, unknown>,
  modalityErrors: Array<ModalityError>,
): Array<ModalityError> => {
  const hiddenErrors: Array<ModalityError> = [];
  modalityErrors.forEach((error) => {
    if (error.is_hidden) {
      hiddenErrors.push(error);
    }
    if (error.exists === false && error.modality === 'MANDATORY' && 'flat_dotnotation' in error && !error.is_hidden) {
      const fieldName = error.field.name;
      setNestedError(
        errors,
        error.flat_dotnotation,
        'required',
        `[TITLE=${t('webedi:errorMessages.required.title', { field: fieldName })}][DESCRIPTION=${t('webedi:errorMessages.required.description', { field: fieldName })}]`,
      );
    }
  });

  return hiddenErrors;
};

const validateData = async (processSpecification: ProcessSpecificationDTO, data: any): Promise<ResolverResult> => {
  setIsValidating(true);
  if ((window as any).procuros_debug) {
    console.log('validating data: ', data);
  }
  const { canonical, errors } = await getCanonicalAndErrors(processSpecification, data);

  if ((window as any).procuros_debug) {
    console.log({ flattendData: data, canonical: canonical });
  }
  const merged: Record<string, unknown> = {};

  const hiddenErrors: Array<ModalityError | DatacheckError> = [];
  hiddenErrors.concat(processDatachecksErrors(merged, errors.datachecks));
  hiddenErrors.concat(processModalityErrors(merged, errors.modality));

  if ((window as any).procuros_debug) {
    console.log({ merged, errors });
  }

  if (hiddenErrors.length > 0) {
    logError(
      new GenericError('WebEDI V2: Hidden errors found', {
        url: window.location.pathname,
        data,
        canonical,
        hiddenErrors,
        fields: processSpecification.fields,
      }),
    );
  }

  setIsValidating(false);
  return {
    values: Object.keys(errors).length ? {} : data,
    errors: merged,
  };
};

// Main resolver function
export const datachecksResolver = (processSpecification: ProcessSpecificationDTO | undefined) => {
  return async (data: any): Promise<ResolverResult> => {
    if ((window as any).procuros_debug) {
      console.warn('running resolver');
    }
    if (!processSpecification) {
      return { values: data, errors: {} };
    }

    try {
      const randomString = Math.random().toString(36).substring(7);
      if ((window as any).procuros_debug) {
        console.time(`validateData-${randomString}`);
      }
      const result = await validateData(processSpecification, data);
      if ((window as any).procuros_debug) {
        console.timeEnd(`validateData-${randomString}`);
      }
      return result;
    } catch (error) {
      console.log('Error in datachecksResolver', error);
      logError(error);
      return { values: data, errors: {} };
    }
  };
};
