import { ui } from '@procuros/datachecks';
import { CellContext, ColumnMeta, RowData } from '@tanstack/react-table';
import classNames from 'classnames';
import { useValue } from 'components/WebEDI/helpers';
import { useMessageFieldErrorModal, MessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { useMemo, useEffect, FocusEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { useDatachecksStore } from 'stores/datachecks/datachecks';
import { DisplayModes } from '../Types';
import {
  useOptionalConfigurations,
  useProcurosAddedValue,
  useCellClassNames,
  isRelativeModification,
  getFieldId,
} from './cellHelpers';
import { RowStatus } from './components/RowStatus/RowStatus';
import { CellProps } from './types';
import { useFormContext } from 'react-hook-form';
import { Input } from 'components/Form/Input';

export type Props<TData, TValue> = CellContext<TData, TValue> & CellProps;

const typeToInputType = (type: ColumnMeta<RowData, unknown>['type'] | undefined) => {
  switch (type) {
    case ui.FieldTypes.number:
    case ui.FieldTypes.monetary:
      return 'number';
    case ui.FieldTypes.date:
      return 'date';
    default:
      return 'text';
  }
};

export const DropdownCellReadOnly = <TData, TValue>({
  cell,
  column,
  row,
  table,
  isHighlighted,
}: Props<TData, TValue>) => {
  const { t } = useTranslation();
  const isValidating = useDatachecksStore((state) => state.isValidating);
  const {
    columnDef: { meta: columnMeta, ...columnDef },
    getIsFirstColumn,
    getIsLastColumn,
  } = column;

  const { id } = cell;

  let fieldId = useMemo(
    () => getFieldId(columnDef, table.options.meta?.dataPrefix, id),
    [columnDef, id, table.options.meta?.dataPrefix],
  );

  const isLineItems = table.options.meta?.dataPrefix.includes('line_items');
  const isRelativeModificationRow = isRelativeModification(
    fieldId,
    row.original,
    DisplayModes.create,
    Boolean(isLineItems),
  );

  if (isRelativeModificationRow && fieldId.endsWith('.amount')) {
    fieldId = fieldId.replace('.amount', '.percentage');
  }

  const inputValue = useValue(row.original, fieldId, columnMeta?.optionalConfig?.expression);
  const { prefix, suffix, isNumeric, formattedValue } = useOptionalConfigurations({
    meta: columnMeta,
    value: inputValue,
    mode: DisplayModes.create,
    rowData: row.original,
    fieldId,
  });

  const type = useMemo(() => {
    return typeToInputType(columnMeta?.type);
  }, [columnMeta?.type]);

  const { register } = useFormContext();
  const { onBlur, ...otherRegister } = register(fieldId, { valueAsNumber: isNumeric });

  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();

  const isAdded = useProcurosAddedValue(row);

  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const cellClassNames = useCellClassNames({
    isLastColumn: getIsLastColumn(),
    isFirstColumn: getIsFirstColumn(),
    row,
    table,
  });

  const inputClassNames = classNames(
    error
      ? 'group-hover:bg-red-100 bg-red-50 focus:!bg-transparent disabled:!bg-red-50'
      : 'group-hover:bg-procuros-green-50 focus:!bg-transparent disabled:!bg-transparent',
    cellClassNames,
    isNumeric ? 'text-end' : 'text-start',
    columnMeta?.optionalConfig?.isStatusColumn ? '!pl-10' : undefined,
  );

  useEffect(() => {
    if (showFieldErrorModal && error) {
      setCurrentError(error);
    }
  }, [showFieldErrorModal, error, setCurrentError]);

  const onBlurHandler: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onBlur(e);
      setShowFieldErrorModal(false);
    },
    [onBlur, setShowFieldErrorModal],
  );

  const onFocusHandler = useCallback(() => {
    setShowFieldErrorModal(true);
  }, [setShowFieldErrorModal]);

  //We need the readOnly for the formatted value to be shown when rendering the type of the Parties
  //On number inputs we can't render formatted values because the input type is number and the formatted value will contain a currency symbol
  const valueToShow = !isNumeric ? formattedValue : inputValue;
  return (
    <>
      <div
        className={classNames(
          'group flex justify-between gap-2 items-center w-full',
          {
            'bg-red-50 focus-within:!bg-transparent focus-within:ring-red-500 focus-within:shadow-red-500 hover:bg-red-100':
              error,
            'hover:bg-procuros-green-50 focus-within:!bg-transparent focus-within:ring-procuros-green-500 focus-within:shadow-procuros-green-500':
              !error,
            'bg-amber-100': isHighlighted,
          },
          cellClassNames,
        )}
        ref={refs.setReference}
      >
        <Input
          wrapperClassName="w-full"
          className={classNames(
            'bg-transparent w-full !border-none px-3 !outline-none text-xs !shadow-none rounded-none py-3 min-w-[120px]',
            inputClassNames,
          )}
          placeholder={
            !formattedValue && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined
          }
          title={formattedValue ? String(formattedValue) : undefined}
          value={valueToShow ?? ''}
          readOnly={true}
          type={type}
          {...otherRegister}
          hasError={Boolean(error)}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          leftIcon={
            columnMeta?.optionalConfig?.isStatusColumn ? (
              <RowStatus
                statusColumn={columnMeta?.optionalConfig?.quantityField}
                currentPath={fieldId}
                isProcurosAdded={isAdded}
                currentColumn={columnDef && 'accessorKey' in columnDef ? (columnDef.accessorKey as string) : ''}
                tooltips={{
                  deleted: t('webedi:inputCell.status.deleted'),
                  partial: t('webedi:inputCell.status.partial'),
                  complete: t('webedi:inputCell.status.completed'),
                }}
              />
            ) : null
          }
          leftAddon={prefix}
          rightAddon={suffix}
          id={fieldId}
          data-field={fieldId}
          data-testid={fieldId}
          min={isNumeric ? 0 : undefined}
          data-1p-ignore
        />
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={false}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
          isValidating={isValidating}
        />
      )}
    </>
  );
};
