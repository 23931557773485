import { ui } from '@procuros/datachecks';
import { useState, useEffect } from 'react';
import { applyRowMasksToData, getUiDetails } from 'services/datachecks/datachecks';
import { logError } from 'services/logging/logging';
import { ProcessSpecificationDTO, MessageV2DTO } from 'support/types';

type DataChecksResult = {
  // @todo fix this type.
  data: unknown;
  uiConfig: Array<ui.Section>;
  isLoading: boolean;
  canonical: MessageV2DTO['canonical'] | null;
};

export const useFlattenData = (processSpecification: ProcessSpecificationDTO | undefined): DataChecksResult => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataChecks, setDataChecks] = useState<any>({});
  const [canonical, setCanonical] = useState<MessageV2DTO['canonical'] | null>(null);
  useEffect(() => {
    if (!processSpecification) {
      return;
    }

    try {
      setIsLoading(true);
      getUiDetails(processSpecification).then((out) => {
        setCanonical(out.canonical);
        setDataChecks({
          data: applyRowMasksToData(out.values, out.view),
          uiConfig: out.view?.map((section: any) => {
            if (section.type === 'line_items') {
              return {
                ...section,
                fields: section.fields.map((field: any) => {
                  if (field.id === 'transport_units') {
                    return {
                      ...field,
                      extraFields: {
                        disabledIfNested: true,
                      },
                    };
                  }
                  return field;
                }),
              };
            }
            return section;
          }),
        });
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      logError(error);
    }
  }, [processSpecification]);

  return {
    uiConfig: dataChecks.uiConfig,
    data: dataChecks.data,
    isLoading,
    canonical,
  };
};
