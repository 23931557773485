import { CommandLineIcon } from '@heroicons/react/24/outline';
import { PlugIcon } from 'components/CustomIcons/PlugIcon';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { ApplicationLogoSmall } from 'components/Logo/ApplicationLogoSmall/ApplicationLogoSmall';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { routeToNewIntegrationPage } from 'support/helpers/navigation/navigation';
import { Connectors, LegacyConnectors } from 'support/types';

export enum ConnectorSelectorActions {
  SHOW_CONTACT_MODAL = 'showContactModal',
  SHOW_CONTACT_PAGE = 'showContactPage',
  GO_TO_CONNECTOR = 'goToConnector',
}

export type ConnectorConfigItem = {
  type: string;
  name: string;
  description?: string;
  icon?: ReactNode;
  badge?: ReactNode;
  key: Connectors;
  legacy?: LegacyConnectors;
  path?: string;
  action?: ConnectorSelectorActions;
  limit?: number;
};

export type ConnectorConfigGroup = {
  type: string;
  name: string;
  items: Array<ConnectorConfigItem>;
};

export const connectorsConfig = (t: TFunction): Array<ConnectorConfigGroup> => [
  {
    type: 'group',
    name: t('integrations:group.edi'),
    items: [
      {
        type: 'item',
        name: t('integrations:connector.WEB_EDI.name'),
        description: t('integrations:connector.WEB_EDI.description'),
        badge: <Badge color={BadgeColor.blue}>{t('integrations:connector.WEB_EDI.badge')}</Badge>,
        icon: (
          <span className="bg-procuros-green-500">
            <ApplicationLogoSmall />
          </span>
        ),
        limit: 1,
        legacy: LegacyConnectors.WEB_EDI_LEGACY,
        key: Connectors.WEB_EDI,
        path: routeToNewIntegrationPage(Connectors.WEB_EDI),
        action: ConnectorSelectorActions.GO_TO_CONNECTOR,
      },
      {
        type: 'item',
        name: t('integrations:connector.AS2.name'),
        description: t('integrations:connector.AS2.description'),
        icon: (
          <span className="bg-procuros-green-500 p-2 text-white">
            <PlugIcon />
          </span>
        ),
        key: Connectors.AS2,
        path: routeToNewIntegrationPage(Connectors.AS2),
        action: ConnectorSelectorActions.GO_TO_CONNECTOR,
      },
      {
        type: 'item',
        name: t('integrations:connector.SFTP.name'),
        description: t('integrations:connector.SFTP.description'),
        icon: (
          <span className="bg-procuros-green-500 p-2 text-white">
            <PlugIcon />
          </span>
        ),
        key: Connectors.SFTP,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
    ],
  },
  {
    type: 'group',
    name: t('integrations:group.erp'),
    items: [
      {
        type: 'item',
        name: t('integrations:connector.XENTRAL_V2.name'),
        key: Connectors.XENTRAL_V2,
        icon: <img className="!border-gray-200 object-contain p-2" src="/assets/logos/connectors/xentral.png" alt="" />,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
      {
        type: 'item',
        name: t('integrations:connector.WECLAPP.name'),
        key: Connectors.WECLAPP,
        icon: <img className="!border-gray-200 object-contain p-2" src="/assets/logos/connectors/weclapp.png" alt="" />,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
      {
        type: 'item',
        name: t('integrations:connector.MICROSOFT_BUSINESS_CENTRAL.name'),
        icon: (
          <img className="!border-gray-200 object-contain" src="/assets/logos/connectors/microsoft-bc.png" alt="" />
        ),
        key: Connectors.MICROSOFT_BUSINESS_CENTRAL,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
      {
        type: 'item',
        name: t('integrations:connector.HAUFE.name'),
        icon: <img className="!border-gray-200 object-contain p-1" src="/assets/logos/connectors/haufe.png" alt="" />,
        key: Connectors.HAUFE,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
      {
        type: 'item',
        name: t('integrations:connector.ODOO.name'),
        icon: <img className="!border-gray-200 object-contain p-1" src="/assets/logos/connectors/odoo.png" alt="" />,
        key: Connectors.ODOO,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
      {
        type: 'item',
        name: t('integrations:connector.OTHER.name'),
        icon: (
          <span className="bg-procuros-green-500 p-2 text-white">
            <PlugIcon />
          </span>
        ),
        key: Connectors.NONE,
        action: ConnectorSelectorActions.SHOW_CONTACT_MODAL,
      },
    ],
  },
  {
    type: 'group',
    name: t('integrations:group.api'),
    items: [
      {
        type: 'item',
        name: t('integrations:connector.API_V2.name'),
        description: t('integrations:connector.API_V2.description'),
        icon: (
          <span className="bg-procuros-green-500 p-2 text-white">
            <CommandLineIcon />
          </span>
        ),

        key: Connectors.API_V2,
        action: ConnectorSelectorActions.SHOW_CONTACT_PAGE,
      },
    ],
  },
];

/**
 * i18n
 * t('integrations:connector.NONE.name')
 * t('integrations:connector.EMAIL.name')
 */
