import { FieldError } from 'react-hook-form';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { sortArrayBasedOnConfig } from './helpers';
import { getLoopArrayPositions } from 'support/helpers/arrays/arrays';
import { ui } from '@procuros/datachecks';

export type WebEDIError = {
  id: string;
  title: string;
  description: string;
  type: FieldError['type'];
};

type State = {
  errors: Array<WebEDIError>;
  errorsMap: Record<string, WebEDIError>;
  currentErrorIndex: number;
  nextErrorIndex: number;
  previousErrorIndex: number;
  numberOfErrors: number;
};

type Actions = {
  setCurrentError: (errorIndex: number) => void;
  clearErrors: () => void;
  setErrors: (errors: Record<string, WebEDIError>, uiConfig: Array<any>) => void;
};

export const initialState: State = {
  errorsMap: {},
  errors: [],
  currentErrorIndex: -1,
  nextErrorIndex: 0,
  previousErrorIndex: 0,
  numberOfErrors: 0,
};

export const useWebEDIErrorsStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,
    setErrors: (errors, uiConfig) => {
      set((state) => {
        const mappederrors = remapErrorsToFirstCell(errors, uiConfig);
        state.errorsMap = mappederrors;
        const arrayOfErrrors = Array.from(Object.values(mappederrors));
        state.errors = sortArrayBasedOnConfig(arrayOfErrrors, uiConfig);

        if (arrayOfErrrors.length === 0) {
          state.currentErrorIndex = -1;
          state.nextErrorIndex = 0;
          state.previousErrorIndex = 0;
          state.numberOfErrors = 0;
        }

        if (state.currentErrorIndex !== -1) {
          const { previous, current, next } = getLoopArrayPositions(state.currentErrorIndex, state.errors);
          state.currentErrorIndex = current;
          state.nextErrorIndex = next;
          state.previousErrorIndex = previous;
        }

        state.numberOfErrors = state.errors.length;
      });
    },
    setCurrentError: (errorIndex: number) => {
      set((state) => {
        const { errors } = state;
        const errorsLength = errors.length;

        if (errorsLength === 0) return;

        if (errorIndex < 0) {
          errorIndex = errorsLength - 1;
        } else if (errorIndex >= errorsLength) {
          errorIndex = 0;
        }

        const { previous, current, next } = getLoopArrayPositions(errorIndex, errors);
        state.currentErrorIndex = current;
        state.nextErrorIndex = next;
        state.previousErrorIndex = previous;
      });
    },

    clearErrors: () => {
      set((state) => {
        state.errorsMap = {};
        state.errors = [];
        state.currentErrorIndex = -1;
        state.nextErrorIndex = 0;
        state.previousErrorIndex = 0;
        state.numberOfErrors = 0;
      });
    },
  })),
);

function remapErrorsToFirstCell(
  errors: Record<string, WebEDIError>,
  uiConfig: Array<ui.Section>,
): Record<string, WebEDIError> {
  const updatedErrors: Record<string, WebEDIError> = {};

  Object.entries(errors).forEach(([errorKey, errorDetail]) => {
    const [sectionType, rowIndex, ...fieldPath] = errorKey.split('.');
    const joinedFildPath = fieldPath.join('.');

    // Look for the matching section
    const matchingSection = uiConfig.find((section) => section.type === sectionType);

    if (matchingSection && matchingSection.fields.length > 0) {
      // Check if the field exists in the section
      const fieldExists = matchingSection.fields.some((field) => field.path === joinedFildPath);

      if (fieldExists) {
        // If field exists, keep original key and error detail
        updatedErrors[errorKey] = errorDetail;
      } else {
        // If field doesn't exist, create new key with first field's path
        const newKey = `${sectionType}.${rowIndex}.${matchingSection.fields[0].path}`;
        // Update error detail with new id
        updatedErrors[newKey] = {
          ...errorDetail,
          id: newKey,
        };
        // Old key is automatically dropped since we're not including it in updatedErrors
      }
    } else {
      // If no matching section found, keep original error
      updatedErrors[errorKey] = errorDetail;
    }
  });

  return updatedErrors;
}
