import Ajv from 'ajv';
import { JSONSchema7 } from 'json-schema';

export const validateAgainstEnrichmentSchema = (enrichmentSchema: JSONSchema7 | undefined, data: unknown) => {
  const validator = new Ajv();
  if (!enrichmentSchema || !data) {
    return false;
  }

  const valid = validator.validate(enrichmentSchema, data);
  if ((window as any).procuros_debug) {
    console.log(validator.errors, data);
  }
  return valid;
};
