import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'support/helpers/currency/currency';
import { SummaryAccordion } from '../SummaryAccordion/SummaryAccordion';
import { useDocumentCurrency } from '../helpers';
import { useInvoiceAndCreditNoteSummary } from '../hooks/useInvoiceAndCreditNoteSummary';
import { DocumentType } from 'support/types';

export const CreateInvoiceAndCreditNoteSummary = ({
  documentType,
}: {
  documentType: DocumentType.invoice | DocumentType.creditNote;
}) => {
  const { t } = useTranslation();
  const currency = useDocumentCurrency();
  const InvoiceAndCreditNoteSummary = useInvoiceAndCreditNoteSummary();
  const isInvoice = documentType === DocumentType.invoice;
  return (
    <SummaryAccordion
      header={{
        label: t('webedi:documentSummary.title', {
          documentType: t(`common:messageTypes.singular.${documentType}`),
        }),
        value: formatCurrency(InvoiceAndCreditNoteSummary.due, currency),
      }}
      bodyItems={[
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.grossAmount.label')
            : t('webedi:documentSummary.creditNote.grossAmount.label'),
          value: formatCurrency(InvoiceAndCreditNoteSummary.subtotal, currency),
        },
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.allowances.label')
            : t('webedi:documentSummary.creditNote.allowances.label'),
          value: formatCurrency(InvoiceAndCreditNoteSummary.allowances, currency),
        },
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.charges.label')
            : t('webedi:documentSummary.creditNote.charges.label'),
          value: formatCurrency(InvoiceAndCreditNoteSummary.charges, currency),
        },
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.taxes.label')
            : t('webedi:documentSummary.creditNote.taxes.label'),
          value: formatCurrency(InvoiceAndCreditNoteSummary.taxes, currency),
        },
      ]}
      footer={{
        label: isInvoice ? t('webedi:documentSummary.invoice.footer') : t('webedi:documentSummary.creditNote.footer'),
        value: formatCurrency(InvoiceAndCreditNoteSummary.due, currency),
      }}
    />
  );
};
