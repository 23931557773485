import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { readCookie } from 'support/helpers/cookies/cookies';
import { setPotentiallyAuthenticated, useIsPotentiallyAuthenticated } from 'services/auth/auth';
import { AuthRoutes } from 'support/types';

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsPotentiallyAuthenticated();
  const [hasCheckedXsrfToken, setHasCheckedXsrfToken] = useState(false);

  useEffect(() => {
    // Check if the user has an XSRF token and set the authentication state
    // This is only done on the first render
    const xsrfToken = readCookie('XSRF-TOKEN');
    if (xsrfToken) {
      setPotentiallyAuthenticated(true);
    }
    setHasCheckedXsrfToken(true);
  }, []);

  useEffect(() => {
    if (!hasCheckedXsrfToken) return;
    if (!isAuthenticated) {
      navigate(AuthRoutes.login, { replace: true });
    }
  }, [isAuthenticated, navigate, hasCheckedXsrfToken]);

  return hasCheckedXsrfToken && isAuthenticated ? <>{children}</> : null;
};
