import { Filter } from 'components/ListTable/types';
import { createMutation, createQuery, createInfiniteQuery } from 'react-query-kit';
import { BASE_URL, createData, fetchData, updateData } from 'services/http/http';
import {
  MessageDTO,
  MessageV2DTO,
  PaginationDTO,
  RawTransactionDTO,
  SenderEnvelopeDTO,
  TransactionDTO,
  TransactionInternalType,
  TransactionState,
} from 'support/types';
import portalSchema from 'support/types/schema-portal';
import { useFailedTransactionsCount } from '../stats/stats';
import { queryClient } from 'services/http/http';
import { resetMessageQueries } from 'support/helpers/repositories/repositories';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import { useInfiniteRelationships, useRelationshipTestDocuments } from '../relationships/relationships';
import { useMessageWithProcessSpecification } from '../messages/messages';

type RawTransactionsResponseDTO = PaginationDTO<RawTransactionDTO>;

type TransactionsResponseDTO = PaginationDTO<TransactionDTO>;

type ListSenderEnvelopesResponse =
  portalSchema.paths['/v1/transactions/sender-envelope']['get']['responses']['200']['content']['application/json'];

type ListMessagesResponse =
  portalSchema.paths['/v1/transactions/message']['get']['responses']['200']['content']['application/json'];

type DropTransactionResponse =
  portalSchema.paths['/v1/transactions/{type}/{id}/drop']['put']['responses']['200']['content']['application/json'];

type DropTransactionsResponse =
  portalSchema.paths['/v1/transactions/bulk/drop']['put']['responses']['200']['content']['application/json'];

type DeliverTransactionsResponse =
  portalSchema.paths['/v1/transactions/bulk/deliver']['put']['responses']['200']['content']['application/json'];

type RecoverTransactionResponse =
  portalSchema.paths['/v1/transactions/{id}/recover']['put']['responses']['200']['content']['application/json'];

type DeliverMessageResponse =
  portalSchema.paths['/v1/transactions/receiver-envelope/{id}/deliver']['put']['responses']['201']['content']['application/json'];

type GetTransactionResponse = {
  transaction: MessageDTO | SenderEnvelopeDTO;
  relatedTransactions: Array<Array<MessageDTO | SenderEnvelopeDTO>>;
};

export const useTransaction = createQuery<GetTransactionResponse, { transactionId?: string }, Error>({
  primaryKey: '/messages',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const response = await fetchData<GetTransactionResponse>(`/v1/transactions/${variables.transactionId}`);

    return response.data;
  },
  enabled: (_, variables) => !!variables.transactionId,
});

export type GetTransactionMessageResponse = {
  transaction: MessageDTO;
  relatedTransactions: Array<MessageDTO>;
};

type GetSenderEnvelopeResponse = {
  transaction: SenderEnvelopeDTO;
  relatedTransactions: Array<MessageDTO>;
};

export const useTransactionMessage = createQuery<GetTransactionMessageResponse, { messageId?: string }, Error>({
  primaryKey: '/messages',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const response = await fetchData<GetTransactionMessageResponse>(
      `/v1/transactions/${TransactionInternalType.message}/${variables.messageId}`,
    );

    return response.data;
  },
  enabled: (_, variables) => !!variables.messageId,
});

export const useSenderEnvelope = createQuery<GetSenderEnvelopeResponse, { senderEnvelopeId?: string }, Error>({
  primaryKey: '/senderEnvelopes',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const response = await fetchData<GetSenderEnvelopeResponse>(
      `/v1/transactions/${TransactionInternalType.senderEnvelope}/${variables.senderEnvelopeId}`,
    );

    return response.data;
  },
  enabled: (data, variables) => !!variables.senderEnvelopeId,
});

export const useSenderEnvelopes = createQuery<ListSenderEnvelopesResponse, { query?: Filter }, Error>({
  primaryKey: '/senderEnvelopes/paginated',
  queryFn: async ({ queryKey: [, variables] }) => {
    const urlParams = buildFilterUrl(variables.query);

    const { data } = await fetchData<ListSenderEnvelopesResponse>('/v1/transactions/sender-envelope' + urlParams);

    return data;
  },
});

export const useMessages = createQuery<ListMessagesResponse, { query?: Filter }, Error>({
  primaryKey: '/messages/paginated',
  queryFn: async ({ queryKey: [, variables] }) => {
    const urlParams = buildFilterUrl(variables.query);

    const { data } = await fetchData<ListMessagesResponse>('/v1/transactions/message' + urlParams);

    return data;
  },
});

export const useExpandedMessages = createQuery<ListMessagesResponse, { query?: Filter }, Error>({
  primaryKey: '/expanded-messages/paginated',
  queryFn: async ({ queryKey: [, variables] }) => {
    const baseFilterUrl = buildFilterUrl(variables.query);
    const urlParams = baseFilterUrl ? `${baseFilterUrl}&full_resources=true` : '?full_resources=true';

    const { data } = await fetchData<ListMessagesResponse>('/v1/transactions/message' + urlParams);

    return data;
  },
});

const determineInternalType = (transaction: RawTransactionDTO): TransactionInternalType => {
  if (transaction.messageId) {
    return TransactionInternalType.message;
  }

  return TransactionInternalType.senderEnvelope;
};

export const useInfiniteTransactions = createInfiniteQuery<TransactionsResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/transactions',
  queryFn: async ({ queryKey: [, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ ...variables.query, cursor: pageParam });

    const { data } = await fetchData<RawTransactionsResponseDTO>('/v1/transactions' + urlParams);

    return {
      ...data,
      items: data.items.map((transaction) => ({
        ...transaction,
        internalType: determineInternalType(transaction),
      })),
    };
  },
  getNextPageParam: (lastPage) => lastPage.nextCursor,
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.previousCursor,
});

export async function invalidateGeneralMessageQueries(
  messageIds: Array<string> | undefined,
  state: TransactionState | undefined,
): Promise<Array<void | Array<void>>> {
  if (state) {
    messageIds?.forEach((transactionId) => {
      updateTransactionState(transactionId, state);
    });
  }

  return await Promise.all([
    queryClient.invalidateQueries(useFailedTransactionsCount.getKey()),
    queryClient.invalidateQueries(useInfiniteTransactions.getKey()),
    queryClient.invalidateQueries(useInfiniteRelationships.getKey()),
    queryClient.invalidateQueries(useRelationshipTestDocuments.getKey()),
    ...(messageIds ?? []).map((id) => resetMessageQueries(queryClient, id)),
    ...(messageIds ?? []).map((id) =>
      queryClient.invalidateQueries(useSenderEnvelope.getKey({ senderEnvelopeId: id })),
    ),
  ]);
}

export const useTransactions = createQuery<TransactionsResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/transactions/paginated',
  queryFn: async ({ queryKey: [, variables] }) => {
    const urlParams = buildFilterUrl(variables.query);

    const { data } = await fetchData<RawTransactionsResponseDTO>('/v1/transactions' + urlParams);

    return {
      ...data,
      items: data.items.map((transaction) => ({
        ...transaction,
        internalType: determineInternalType(transaction),
      })),
    };
  },
});

export type BulkActionVariables = {
  items: Array<{ transactionId: string }>;
};

type DropTransactionVariables = {
  id: string;
  reason: string;
  type: TransactionInternalType;
};

type DropTransactionsVariables = BulkActionVariables & {
  reason: string;
};

export function updateTransactionState(id: string, state: TransactionState) {
  return new Promise((resolve) => {
    const messageQueryKey = useMessageWithProcessSpecification.getKey({ messageId: id });
    const messageQuery = queryClient.getQueryData<any>(messageQueryKey);

    if (messageQuery) {
      const newData = {
        ...messageQuery,
        data: {
          ...messageQuery.data,
          state,
        },
      };

      queryClient.setQueryData(messageQueryKey, newData);
    }

    const queries = queryClient.getQueriesData<any>(useInfiniteTransactions.getKey());
    // "queries" is an array of arrays, where the first element is the query key and the second element is the query data
    // we want to loop through all queries data, find the "pages" array, find the "items" array, and then change the state of the item
    queries.forEach((query) => {
      const [q, data] = query;
      const newPages = data?.pages?.map((page: any) => ({
        ...page,
        items: page.items.map((item: TransactionDTO) => (item.id === id ? { ...item, state } : item)),
      }));
      queryClient.setQueryData(q, () => ({ ...data, pages: newPages }));
    });
    resolve(true);
  });
}

export const useDropTransaction = createMutation<void, DropTransactionVariables, Error>({
  mutationFn: async ({ id, reason, type }) => {
    await updateData<DropTransactionResponse>(`/v1/transactions/${type}/${id}/drop`, { reason });
  },
  onSuccess: async (_, variables) => {
    return await invalidateGeneralMessageQueries([variables.id], TransactionState.dropped);
  },
});

export const useDropTransactions = createMutation<DropTransactionsResponse, DropTransactionsVariables, Error>({
  mutationFn: async ({ items, reason }) => {
    const { data } = await updateData<DropTransactionsResponse>(`/v1/transactions/bulk/drop`, { items, reason });
    return data;
  },
  onSuccess: async (data) => {
    const successItems = data.data.succeded;
    return await invalidateGeneralMessageQueries(successItems, TransactionState.dropped);
  },
});

export const useDeliverTransactions = createMutation<DeliverTransactionsResponse, BulkActionVariables, Error>({
  mutationFn: async ({ items }) => {
    // mock
    // return { success: true, data: { succeded: ['1', '2'], failed: [] } };
    const { data } = await updateData<DropTransactionsResponse>(`/v1/transactions/bulk/deliver`, { items });
    return data;
  },
  onSuccess: async (data) => {
    const successItems = data.data.succeded;
    return await invalidateGeneralMessageQueries(successItems, undefined);
  },
});

type RecoverTransactionVariables = {
  id: string;
};

export const useRecoverTransaction = createMutation<void, RecoverTransactionVariables, Error>({
  mutationFn: async ({ id }) => {
    const { data } = await updateData<RecoverTransactionResponse>(`/v1/transactions/${id}/recover`, {});
    return data;
  },
  onSuccess: async (data, variables) => {
    // optimistic update query cache
    return await invalidateGeneralMessageQueries([variables.id], TransactionState.pending);
  },
});

type RecoverTransactionsResponse =
  portalSchema.paths['/v1/transactions/bulk/recover']['put']['responses']['200']['content']['application/json'];

export const useRecoverTransactions = createMutation<RecoverTransactionsResponse, BulkActionVariables, Error>({
  mutationFn: async ({ items }) => {
    const { data } = await updateData<RecoverTransactionsResponse>(`/v1/transactions/bulk/recover`, { items });
    return data;
  },
  onSuccess: async (data) => {
    // optimistic update query cache (for succeded items)
    const successItems = data.data.succeded;
    return await invalidateGeneralMessageQueries(successItems, TransactionState.pending);
  },
});

type ConfirmMessageReceivalVariables = {
  messageId: string;
  receiverEnvelopeId: string;
};

export const useConfirmMessageReceival = createMutation<void, ConfirmMessageReceivalVariables, Error>({
  mutationFn: async ({ receiverEnvelopeId }) => {
    await updateData<DeliverMessageResponse>(`/v1/transactions/receiver-envelope/${receiverEnvelopeId}/deliver`);
  },
  onSuccess: async (data, variables) =>
    await Promise.all([
      resetMessageQueries(queryClient, variables.messageId),
      queryClient.invalidateQueries(useInfiniteTransactions.getKey()),
    ]),
});

export const downloadAsCSV = async (message: MessageDTO | MessageV2DTO) => {
  window.open(`${BASE_URL}v1/transactions/${message.type}/${message.id}/csv/download`, '_blank');
};

export const downloadAsPDF = async (message: MessageDTO | MessageV2DTO) => {
  window.open(`${BASE_URL}v1/transactions/${message.type}/${message.id}/pdf/download`, '_blank');
};

type ShareTransactionErrorRequest =
  portalSchema.paths['/v1/transactions/{id}/share-error']['post']['requestBody']['content']['application/json'];
type ShareTransactionErrorResponse =
  portalSchema.paths['/v1/transactions/{id}/share-error']['post']['responses']['200']['content']['application/json'];

export const useShareTransactionError = createMutation<
  ShareTransactionErrorResponse,
  ShareTransactionErrorRequest & { id: string },
  Error
>({
  mutationFn: async ({ id, email }) => {
    const { data } = await createData<ShareTransactionErrorResponse>(`/v1/transactions/${id}/share-error`, {
      email,
    });
    return data;
  },
});

export const downloadReceiverEnvelope = async (receiverEnvelopeId: string) => {
  window.open(`${BASE_URL}v1/transactions/receiver-envelope/${receiverEnvelopeId}/download`, '_blank');
};
