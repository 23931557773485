import { CloudArrowUpIcon, DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState, useCallback, ReactNode } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { LoaderResult } from './types';
import { classNames } from 'support/helpers/generic/generic';
import { FieldError } from 'react-hook-form';
import { Input } from '../Input/Input/Input';
import { LoadingLogo } from 'components/Loading/LoadingLogo';

const classes = {
  error: 'border-red-300',
  default: 'border-gray-300',
  dragActive: 'border-procuros-green-300 bg-procuros-green-50',
  loading: 'border-0 bg-gray-100 text-gray-300',
};

type FileInputProps = {
  onLoad: (data: any, filename: string) => void;
  onError: (error?: Error) => void;
  onClear?: () => void;
  validate?: <T>(data: Array<T> | T) => boolean;
  accept?: DropzoneProps['accept'];
  label?: string;
  loadingLabel?: string | ReactNode;
  isProcessing?: boolean;
  processingLabel?: string | ReactNode;
  description?: string;
  loader: (file: any) => Promise<LoaderResult<unknown>>;
  errors?: FieldError;
  defaultFilename?: string | null;
  extraInformation?: Array<string>;
  dropzoneClassname?: string;
  hideSelectedFile?: boolean;
};

export const FileInput = ({
  onLoad,
  onError,
  onClear,
  validate,
  accept,
  label,
  loadingLabel,
  isProcessing,
  processingLabel,
  description,
  loader,
  errors,
  defaultFilename,
  extraInformation,
  dropzoneClassname,
  hideSelectedFile,
}: FileInputProps) => {
  const { t } = useTranslation();
  const [filename, setFilename] = useState(defaultFilename);
  const [isLoading, setIsLoading] = useState(false);

  const onLoadComplete = useCallback(
    (filename: string) => (results: LoaderResult<unknown>) => {
      if (validate && !validate(results.data)) {
        onError();
      } else {
        onLoad(results.data, filename);
        setFilename(filename);
      }
    },
    [onError, onLoad, validate],
  );

  const onDropAccepted = useCallback(
    (acceptedFiles: Array<File>) => {
      setIsLoading(true);
      Promise.all(acceptedFiles.map(loader))
        .then((data) => {
          data.forEach((data, index) => {
            onLoadComplete(acceptedFiles[index].name)(data);
          });
        })
        .catch(onError)
        .finally(() => setIsLoading(false));
    },
    [onLoadComplete, loader, onError],
  );

  const onDropRejected = useCallback(() => {
    onError();
  }, [onError]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    disabled: isLoading || isProcessing,
    multiple: false,
    accept,
    noClick: true,
    noKeyboard: true,
  });

  const reset = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setFilename('');
    onClear?.();
  };

  return (
    <div {...getRootProps()}>
      <Input {...getInputProps()} label={label} />
      <div
        className={classNames(
          'border-dashed border-2 rounded-md flex flex-col justify-center items-center gap-1 py-8',
          classes[errors ? 'error' : 'default'],
          isDragActive ? classes.dragActive : null,
          isLoading || isProcessing ? classes.loading : null,
          dropzoneClassname,
        )}
      >
        {isLoading || isProcessing ? (
          <>
            <div className="size-8">
              <LoadingLogo />
            </div>
            <p className="mt-3 text-center text-sm text-gray-500">
              {isLoading && loadingLabel ? loadingLabel : isProcessing && processingLabel ? processingLabel : null}
            </p>
          </>
        ) : (
          <>
            <CloudArrowUpIcon className="h-9 w-11 stroke-gray-400" />
            <p className="text-sm font-medium text-gray-600">
              <Button variant="text" onClick={open} analyticsId="upload_file_picker_open">
                {t('common:components.fileInput.text1')}
              </Button>{' '}
              {t('common:components.fileInput.text2')}
            </p>
            {description && <p className="text-sm text-gray-500">{description}</p>}
          </>
        )}
      </div>
      {!!errors && <p className="mt-2 text-sm text-red-600">{errors.message}</p>}
      {filename && !hideSelectedFile ? (
        <div className="mt-2 rounded-md border border-gray-200 px-2 py-3">
          <div className="flex items-center gap-2">
            <DocumentIcon className="h-4 w-6 stroke-gray-400" />
            <div className="flex w-full items-center justify-between gap-2 text-sm text-gray-900">
              <p>{filename}</p>
              <Button variant="minimal" size="extra-small" onClick={reset} analyticsId="upload_file_reset">
                <TrashIcon className="h-4 w-6 stroke-gray-600" />
              </Button>
            </div>
          </div>
          <div className="space-y-2 px-8">
            {extraInformation?.map((info) => (
              <p key={info} className="text-xs text-gray-600">
                {info}
              </p>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
